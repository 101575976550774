import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Map consulting and project guidance`}</h2>
    <p>{`Map project options can become overwhelming and it can be difficult to know
where to start, but we can help answer some of the essential questions to get
your map project up and running.`}</p>
    <h3>{`Initial map planning`}</h3>
    <p>{`What are your map project's goals? What is the scope of your project, spatial
data complexity, and how does it fit into your timeline and budget? We will
answer those questions for you and provide guidance on getting your map started.`}</p>
    <h3>{`Choosing map data formats`}</h3>
    <p>{`What is the source of your geographic data? Do you have a shiny set of GIS
shapefiles, a dusty old spreadsheet, or a bar napkin with that "MAPsterpiece"
you sketched over happy hour? Whatever the case, we can help you choose a format
that is maintainable for future updates and suited for your project's goals.`}</p>
    <h3>{`Answering the most technical questions`}</h3>
    <p>{`Some of the most simplest (and nerdiest) questions often get overlooked, but we
will shed some light on the critical info, such as:`}</p>
    <ul>
      <li parentName="ul">{`Where will the map "live"?`}</li>
      <li parentName="ul">{`Any server requirements? What languages and applications are needed?`}</li>
      <li parentName="ul">{`Do you have in-house IT staff and, if so, what is their availability?`}</li>
      <li parentName="ul">{`Will your map data require a full-on spatial data or just simple static files?`}</li>
      <li parentName="ul">{`Who needs access to what in regards to file management and storage?`}</li>
      <li parentName="ul">{`Who is responsible for day-to-day data management workflows?`}</li>
    </ul>
    <h2>{`Basic training when applicable`}</h2>
    <p>{`Your data is your responsibility in projects with ongoing data updates, but we
want to make sure you know what you're doing! Regardless of the project, we
won't leave you hanging in terms of documentation and guidance. We want to ensure that you can comfortably maintain your own data and content, covering aspects like:`}</p>
    <ul>
      <li parentName="ul">{`For most interactive map data, `}<em parentName="li">{`GIS`}</em>{` (Geographic Information Systems) is at the core. GIS can be a daunting topic for the uninitiated, but it doesn't need to be if we break it down into simple and repeatable steps you can follow.`}</li>
      <li parentName="ul">{`Depending on the project, other `}<em parentName="li">{`online map platforms`}</em>{` may be used. For example, A Better Map has worked on a couple of projects using `}<a href="https://carto.com/" target="_blank" rel="noopener noreferrer" title="Carto home page">{`Carto`}</a>{` and provided the necessary guidance for data stewards to edit their work without assistance.`}</li>
      <li parentName="ul">{`In addition to such tech-specific training, we can also provide guidance for other `}<em parentName="li">{`day-to-day workflow`}</em>{` essentials such as image optimization, file management, and data operations.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      