import { navigate } from 'gatsby'

// Friendly little valid ID for href's
export const convertToValidId = heading =>
  heading.replace(/\W/g, '-').toLowerCase()

// TODO: no default export
export default async function sendElectronicMail({
  url = '/.netlify/functions/send-email',
  formData = {},
}) {
  // TODO: async/await error handling: https://javascript.info/async-await
  // TODO: Using Fetch: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // body data type must match "Content-Type" header
    body: JSON.stringify(formData, null, 2),
  })
  const responseJson = await response.json()

  // TODO: try/catch then this (pass props indicating success/fail instead of
  // having two separate pages):
  navigate('/contact/contact-form-success')

  return responseJson
}
