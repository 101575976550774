import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
import { Link as GatsbyLink } from 'gatsby';
import BigCenteredBtn from '../../components/layout-pieces/big-centered-btn';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`GPS and geolocation`}</h2>
    {
      /* NOTE: careful with that map ctrls href as those headings are in flux */
    }
    <p>{`Most internet users now have smartphones equipped with location-sensing
technology including GPS. This is an enhancement we can add to allow your users
to find something relative to their current location. To see an example, read
about the `}<GatsbyLink to="/portfolio/sleeping-bear-heritage-trail" mdxType="GatsbyLink">{`Sleeping Bear
Heritage Trail map`}</GatsbyLink>{` capabilities.`}</p>
    <h2>{`Direct links to map features`}</h2>
    <p>{`Instead of describing a series of steps to find a certain place on a map, that
place can be `}<em parentName="p">{`directly linked`}</em>{` through a URL. For example, take a look at this`}</p>
    <a href="https://friendsofsleepingbear.org/sbht-i-map/popup/features/668" target="_blank" title="Direct link to Great Dunes vista" rel="noopener noreferrer">
  Lake Michigan viewpoint
    </a> on a map we did for Friends of Sleeping
Dunes. The URL corresponds directly to the feature and makes it very convenient
for things like sharing on social media.
    <h2>{`External third-party capabilities`}</h2>
    <p>{`Interactive web maps can also expand into a larger ecosystem of nerdery with
many interesting code libraries and services. The possibilities are endless, but
below are some of the more interesting options.`}</p>
    <h3>{`Charts, graphs, and data visualization`}</h3>
    <p>{`Maps are data and data is beauty, but not without a little help. Fortunately we
can integrate some of the many available data visualization options to help
users interpret and analyze your project's data. Here are some engaging tools
that promote the data experience in a meaningful, visually appealing way:`}</p>
    <ul>
      <li parentName="ul"><a href="https://developers.google.com/chart/interactive/docs/gallery" target="_blank" rel="noopener noreferrer" title="Google Charts Chart Gallery">
  Google Charts
        </a> offers
clean, straightforward ways of visualizing data through colorful graphs and
charts.</li>
      <li parentName="ul">{`A more advanced alternative, `}<a href="https://github.com/d3/d3/wiki/
Gallery#visual-index" target="_blank" rel="noopener noreferrer" title="D3 JavaScript visualization gallery">{`D3.js`}</a>{` takes charts to another level with even more data visualization capabilities.`}</li>
    </ul>
    <h3>{`Publicly available scientific data`}</h3>
    <p>{`The natural world is mapped and researched extensively, and research means data.
Some organizations even provide their data in a web-friendly format that can be
readily consumed by interactive maps:`}</p>
    <ul>
      <li parentName="ul">{`For `}<em parentName="li">{`bird enthusiasts`}</em>{` there is `}<a href="http://ebird.org/ebird/hotspots" rel="noopener noreferrer" target="_blank" title="Worldâ€™s largest
biodiversity-related citizen science project">{`a great tool called eBird`}</a>{`
that can be integrated into interactive maps to display locations and data of
user-submitted bird sightings.`}</li>
      <li parentName="ul">{`When it comes to incorporating real-time `}<em parentName="li">{`hydrology`}</em>{` data into a map, USGS `}<a rel="noopener noreferrer" href="http://waterdata.usgs.gov/nwis/rt" target="_blank" title="USGS Current Water Data for the Nation">{`has that
covered`}</a>{` in a publicly available format.`}</li>
    </ul>
    <h3>{`Social media developer platforms`}</h3>
    <p>{`In terms of integrating interactive maps with social media, there is no shortage
of options in that department either.`}</p>
    <ul>
      <li parentName="ul">{`Want your map to show `}<em parentName="li">{`tweets`}</em>{` in a certain area? Twitter's developer APIs
make that possible.`}</li>
      <li parentName="ul">{`How about `}<em parentName="li">{`photos`}</em>{`? Instagram has its own platform for querying their massive
database and displaying location-based, map-friendly results.`}</li>
    </ul>
    <BigCenteredBtn to="/contact" mdxType="BigCenteredBtn">Contact us</BigCenteredBtn>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      