// NOTE: note enough justification for overkill of GraphQL. Small site so
// simpler direct config is easier, especially since link sections are different
// between footer and nav.
export default [
  { text: 'Home', to: '/', title: 'A Better Map home page' },
  {
    text: 'Why go interactive?',
    to: '/advantages-of-interactive-maps',
    title: 'Reasons to use interactive maps',
  },
  {
    text: 'See examples',
    to: '/uses-for-interactive-maps',
    title: 'Use cases for interactive maps',
  },
  {
    text: 'Make a map',
    to: '/contact',
    title: 'Contact A Better Map',
    isButton: true, // TODO: scrap this confusing garbage, add btn separately
  },
]

export const footerLinks = [
  { text: 'Home', to: '/', title: 'A Better Map home page' },
  {
    text: 'What we do',
    to: '/products-and-services',
    title: 'Interactive map services',
  },
  {
    text: 'Why choose A Better Map',
    to: '/products-and-services/why-choose-a-better-map',
    title: 'Reasons to use our company',
  },
  { text: 'About us', to: '/about-us', title: 'About A Better Map' },
  { text: 'Contact', to: '/contact', title: 'Contact A Better Map' },
  // TODO: add blog
]

export const footerLinksInspiration = [
  {
    text: 'Reasons to go interactive',
    to: '/advantages-of-interactive-maps',
    title: 'Why you need an interactive map',
    nested: false,
  },
  {
    text: 'Our maps:',
    to: '/portfolio',
    title: 'Interactive maps portfolio',
    nested: false,
  },
  {
    text: 'Sleeping Bear Heritage Trail',
    to: '/portfolio/sleeping-bear-heritage-trail',
    title: 'Sleeping Bear Heritage Trail portfolio page',
    nested: true,
  },
  {
    text: 'Sheridan Community Land Trust',
    to: '/portfolio/sheridan-community-land-trust',
    title: 'Sheridan Community Land Trust portfolio page',
    nested: true,
  },
  {
    text: '1947 Partition Archive',
    to: '/portfolio/1947-partition-archive',
    title: '1947 Partition Archive portfolio page',
    nested: true,
  },
  {
    text: 'Other examples',
    to: '/uses-for-interactive-maps',
    title: 'Use cases for interactive maps',
    nested: false,
  },
]
