import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex, Link } from 'rebass/styled-components'

import Layout from '../components/layout'

const MissionStatement = () => (
  <Box>
    <h2>Mission statement</h2>
    <p>
      The objective of A Better Map is to create digital interactive maps that
      are engaging, enjoyable, and intuitive. We have a passion for maps and we
      see tremendous value in creating products through technologies that cater
      to modern users and devices.
    </p>
  </Box>
)

const CompanyHistory = () => (
  <Box>
    <h2>Company history</h2>
    <p>
      A Better Map was founded as a sole proprietorship in Alpena, Michigan, in
      2014 and became an LLC in Fort Collins, Colorado, in 2019. It was built
      out of a love for maps and a need to bridge the gap between desktop GIS
      and web development.
    </p>
  </Box>
)

const Experience = () => (
  <Box>
    <h2>Experience</h2>
    <p>
      The team's resume includes over a decade of GIS experience and 7+ years of
      intensive web development with a specialization in web mapping. The
      company has served such diverse clients as{' '}
      <Link as={GatsbyLink} to="/portfolio/sleeping-bear-heritage-trail">
        Friends of Sleeping Bear Dunes
      </Link>{' '}
      and{' '}
      <Link as={GatsbyLink} to="/portfolio/1947-partition-archive">
        The 1947 Partition Archive
      </Link>
      .
    </p>
  </Box>
)

const jStats = [
  {
    heading: 'States visited',
    body:
      'Thanks to a work trip in 2018, finally made it to Hawaii. Boom. 🌺',
    value: 50,
  },
  {
    heading: 'Years of coding',
    body:
      'Deep in the nerdery, and not just for work: volunteering, IoT, and automation.',
    value: 7,
  },
  {
    heading: 'Years of GIS',
    body: 'Data collection, data management, and all (GPS) points in between.',
    value: 10,
  },
  {
    heading: 'Passion for maps',
    body:
      'Not every day you meet a former 8th grade Geography Bee champ, but you do TODAY.',
    value: 1,
  },
]

const JasonInNumbers = ({ stats }) => (
  <>
    <h3>Jason in numbers</h3>
    <Box
      as="ul"
      id="jason-in-numbers"
      textAlign="center"
      variant="listUnstyled"
      mt={10}
      sx={{
        display: 'grid',
        gridColumnGap: 6,
        gridTemplateColumns: [
          null,
          null,
          `repeat(auto-fill, minmax(325px, 1fr))`,
        ],
      }}
    >
      {stats.map(({ heading, body, value }) => (
        <Box as="li" key={heading} mb={[7, null, null, 'unset']}>
          <Flex
            height="9rem"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mb={6}
            style={{
              position: 'relative',
            }}
          >
            <Flex
              fontSize={['10rem', null, '9rem']}
              alignItems="center"
              justifyContent="center"
              color="grays.2"
              width="100%"
              height="100%"
            >
              {value.toString()}
            </Flex>
            <Flex
              fontSize={[6, null, null, 5]}
              fontFamily="heading"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '100%',
                lineHeight: 1.15,
                margin: 0,
                position: 'absolute',
                width: '100%',
              }}
            >
              {heading}
            </Flex>
          </Flex>
          <p>{body}</p>
        </Box>
      ))}
    </Box>
  </>
)

const OurTeam = ({ imageSharp }) => (
  <>
    <h2>Our team</h2>
    <Box
      sx={{
        display: [null, null, 'grid'],
        gridTemplateColumns: [null, null, '1fr auto'],
        gridColumnGap: 8,
      }}
    >
      <div>
        <p>
          A Better Map consists of a sole member, owner, and operator, Jason
          Lampel.
        </p>
        <h3>An early start</h3>
        <p>
          A Michigan native, Jason gained an early love for geography age while
          traveling the country in a semi truck with his parents who taught him
          how to read maps at a very young age. He has pursued that passion ever
          since, with a few nerdy geographic achievements along the way:
        </p>
      </div>
      <Box
        as={Img}
        fluid={imageSharp.fluid}
        style={{ width: 320 }}
        imgStyle={{ width: 320, height: 230 }}
      />
    </Box>
    <JasonInNumbers stats={jStats} />
    <CurrentResume />
    <h3>Additional info</h3>
    <p>
      If you'd like to know anything else, please{' '}
      <Link as={GatsbyLink} to="/contact">
        shoot us a message
      </Link>{' '}
      and we will get back to you shortly.
    </p>
  </>
)

const CurrentResume = () => (
  <>
    <h3>Current resume</h3>
    <p>
      You can find a relatively current version of Jason's resume in a couple of
      spots:
    </p>
    <ul className="gradiented-links">
      <li>
        <Link
          href="https://drive.google.com/file/d/1W9MZSgJk0SN14M1QqqJkx4VAvbOuvNUq/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          PDF on Google Drive
        </Link>
      </li>
      <li>
        <Link
          href="https://www.linkedin.com/in/jason-lampel-607b1732/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn profile
        </Link>
      </li>
    </ul>
  </>
)

const AboutUs = ({ pageContext, data }) => {
  const intro =
    'A Better Map is a small member-owned business that specializes in designing and developing interactive web maps. Our skillset focuses on combining geographic information with web technologies to produce high-quality, user-friendly interactive maps.'

  return (
    <Layout title="About Us" intro={intro} pageContext={pageContext}>
      <MissionStatement />
      <CompanyHistory />
      <Experience />
      <OurTeam imageSharp={data.imageSharp} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query JasonProfilePic {
    imageSharp(fluid: { originalName: { eq: "profile-pic.jpg" } }) {
      fluid(maxWidth: 400, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export default AboutUs
