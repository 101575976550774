import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Button, Link } from 'rebass/styled-components'

// This got gross fast, but basically want to have a big... centered button that
// supports `as` for hyperlinks (Gatsby/Rebass).
const BigCenteredBtn = ({
  children,
  to,
  href,
  title,
  openInNewTab,
  variant = 'primary',
}) => (
  <Flex justifyContent="center" mt={8}>
    {!to && !href ? (
      <Button variant={variant}>{children}</Button>
    ) : (
      <Button
        variant={variant}
        as={href ? Link : GatsbyLink}
        href={href || null}
        to={to || null}
        title={title}
        rel={openInNewTab && 'noopener noreferrer'}
        target={openInNewTab && '_blank'}
      >
        {children}
      </Button>
    )}
  </Flex>
)

export default BigCenteredBtn
