import React from 'react'
import { Box } from 'rebass/styled-components'
import { Label, Input, Select, Textarea } from '@rebass/forms'

// TODO: put responsive into Extree.jsx and/or move that file in extra-generic
import ResponsiveGrid from '../extra-generic/responsive-grid'

const OtherDetails = ({ register, includeDeets }) => (
  <Box as="section" pb={7} sx={{ p: { mt: 0 } }}>
    {includeDeets && <h2>Other info</h2>}
    <ResponsiveGrid numTabletCols={3}>
      <Box mb={6} style={{ gridColumn: '1/2' }}>
        <Label htmlFor="referred-src-type">
          <span>How did you hear about us?</span>
        </Label>
        <Select id="referred-src-type" name="referred-src-type" ref={register}>
          <option value="website">Website</option>
          <option value="search-engine">Search engine</option>
          <option value="social-media">Social media</option>
          <option value="email">Email</option>
          <option value="org">Organization</option>
          <option value="individual">Individual</option>
          <option value="other">Other</option>
        </Select>
      </Box>
      <p style={{ gridColumn: '2/4' }}>
        <Label htmlFor="referred-src-name">
          <span>Referral source</span>
        </Label>
        <Input
          type="text"
          id="referred-src-name"
          name="referred-src-name"
          placeholder="Who can we thank?"
          ref={register}
        />
      </p>
    </ResponsiveGrid>
    <p>
      <Label htmlFor="general-comments-questions">
        <span>Comments and questions</span>
      </Label>
      <Textarea
        id="general-comments-questions"
        name="general-comments-questions"
        rows="5"
        placeholder="Details about your ideal map, examples, current map status, etc."
        ref={register}
      />
    </p>
  </Box>
)

export default OtherDetails
