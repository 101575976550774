import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

// TODO: use aliases for smaller images like thumbs, profile landing links
const DynamicImage = ({
  imgName,
  relImgBaseDir,
  imgStyle = {},
  wrapperStyle = {},
}) => (
  <StaticQuery
    query={graphql`
      fragment fragmentParentFields on File {
        id
        name
        relativeDirectory
      }
      query {
        allImageSharp {
          edges {
            node {
              # Wide container
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
                presentationWidth
              }
              parent {
                id
                ...fragmentParentFields
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Check against parent directory in case there is non-unique image name
      // (e.g. responsive).
      const image = data.allImageSharp.edges.find(
        edge =>
          edge.node.fluid.originalName === imgName &&
          (`${relImgBaseDir}` === edge.node.parent.relativeDirectory ||
            !relImgBaseDir)
      )

      if (!image) {
        return null
      }

      return (
        <Img
          fluid={image.node.fluid}
          imgStyle={{ ...imgStyle }}
          style={wrapperStyle}
        />
      )
    }}
  />
)

export default DynamicImage
