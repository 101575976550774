import React from 'react'
import { Box } from 'rebass/styled-components'

const createColDefs = numCols => {
  let colDefs = '1fr'

  for (let i = 0; i < numCols - 1; i++) {
    if (i === numCols) {
      colDefs += ' 1fr' // no trailing space for last one
    } else {
      colDefs += ' 1fr '
    }
  }
  // debugger

  return colDefs
}

const ResponsiveGrid = ({ children, numDeskCols = 3, numTabletCols = 2 }) => {
  return (
    <Box
      sx={{
        display: [null, null, 'grid'],
        gridTemplateColumns: [
          null,
          null,
          createColDefs(numTabletCols),
          createColDefs(numDeskCols),
        ],
        gridColumnGap: [null, null, 6],
      }}
    >
      {children}
    </Box>
  )
}

export default ResponsiveGrid
