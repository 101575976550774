import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Text, Link } from 'rebass/styled-components'

const Breadcrumbs = ({ crumbs, currentPageTitle }) => {
  const numCrumbs = crumbs.length
  const parentLinks = crumbs.map((crumb, i) => {
    const { pathname, crumbLabel } = crumb
    const friendlyLabel = crumbLabel.toLowerCase().replace(/-/g, ' ')

    // Do different stuff for the last item
    return numCrumbs !== i + 1 ? (
      <li
        key={crumbLabel}
        css={`
          :after {
            content: '>';
            margin: 0 0.2rem;
            color: hsl(0, 0%, 48%); // grays.9
          }
        `}
      >
        <Link
          as={GatsbyLink}
          to={pathname}
          title={friendlyLabel}
          color="linkColor"
          // Safari iOS: evidently no support of `first-letter` for inline elems
          sx={{ display: 'inline-block' }}
        >
          {friendlyLabel}
        </Link>
      </li>
    ) : (
      <Text key={crumbLabel} as="li" aria-current="page" color="grays.9">
        {currentPageTitle}
      </Text>
    )
  })

  return (
    <Box
      as="nav"
      mb={8}
      pb={4}
      fontSize="0.85rem"
      sx={{
        borderBottomWidth: '1px',
        borderBottomStyle: 'dashed',
        borderColor: 'grays.2',
      }}
    >
      <Box
        as="ol"
        variant="listUnstyled"
        aria-label="breadcrumb"
        css={`
          a::first-letter,
          li::first-letter {
            text-transform: uppercase;
          }
          li {
            display: inline-block;
          }
        `}
      >
        {parentLinks}
      </Box>
    </Box>
  )
}

export default Breadcrumbs
