import React from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import BigCenteredBtn from '../components/layout-pieces/big-centered-btn'

const WorkExample = ({ fluid }) => (
  <Box as="figure" mx="auto" mt={[10, null, null, 'unset']}>
    <Box
      as={Img}
      alt="Lake Michigan dunes viewpoint"
      fluid={fluid}
      sx={{
        color: 'advantagesImgShadow',
        boxShadow: '5px 5px 12px 0 currentColor',
      }}
      style={{ maxWidth: fluid.presentationWidth, margin: '0 auto' }}
      imgStyle={{ objectFit: 'contain' }}
    />
    <figcaption>
      Interactive map example: Sleeping Bear Heritage Trail map with Lake
      Michigan viewpoint in Sleeping Bear Dunes National Lakeshore
    </figcaption>
  </Box>
)

const GeneralServices = () => (
  <>
    <h2>General mapping services</h2>
    <p>
      A Better Map also provides{' '}
      <GatsbyLink to="/products-and-services/general-map-services">
        general mapping services
      </GatsbyLink>{' '}
      as needed. These include:
    </p>
    <ul>
      <li>Map consulting and project planning</li>
      <li>Web map workflow development</li>
      <li>Training on creating map data and content</li>
    </ul>
    <BigCenteredBtn variant="monster" to="/contact">
      Contact us
    </BigCenteredBtn>
  </>
)

const ProductsAndServices = ({ pageContext, data }) => {
  const intro =
    'A Better Map designs and develops digital interactive maps in a variety of flavors. Whether you are starting a web map from scratch or want to upgrade an existing map, we have a solution for you.'

  return (
    <Layout
      title="Map products and services"
      intro={intro}
      pageContext={pageContext}
    >
      <h2>Types of maps we provide</h2>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: [null, null, null, '1fr 1fr'],
          gridColumnGap: 8,
        }}
      >
        <div>
          <p>
            Interactive maps come in all shapes and sizes but, regardless of the
            project, our maps follow a handful of{' '}
            <GatsbyLink to="/products-and-services/fundamentals-every-map">
              core values
            </GatsbyLink>{' '}
            whenever possible. Read more about the{' '}
            <GatsbyLink to="/products-and-services/types-of-maps-we-provide">
              types of maps
            </GatsbyLink>{' '}
            we provide, including:
          </p>
          <ul>
            <li>Standalone maps separate from a website</li>
            <li>Integration with existing website</li>
            <li>Simple embeds (e.g. Google Maps)</li>
          </ul>
        </div>
        <WorkExample fluid={data.imageSharp.fluid} />
      </Box>
      <h2>Advanced map customizations</h2>
      <p>
        For details on some of the bells and whistles we can add to interactive
        maps, read about our{' '}
        <GatsbyLink to="/products-and-services/advanced-map-customizations">
          advanced map customizations
        </GatsbyLink>
        .
      </p>
      <GeneralServices />
    </Layout>
  )
}

export const pageQuery = graphql`
  query HeyWeMadeThis {
    imageSharp(fluid: { originalName: { eq: "lake-mi-viewpoint.jpg" } }) {
      fluid(maxWidth: 600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
        presentationWidth
      }
    }
  }
`

export default ProductsAndServices
