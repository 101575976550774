import React from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import PortfolioItemLink from '../components/layout-pieces/link-to-portfolio-item'

const Blog = ({ data, pageContext }) => {
  const { allMd } = data
  const pageTitle = 'Interactive Map Blog'
  const pageIntro =
    'Sometimes you eat the blog and, well, sometimes the blog eats you.'

  // TODO: top_nav_menu: true
  // TODO: emphasize somewhere or multiple places that the whole process is a
  // collaborative effort, not just a one - nerd show over here.

  return (
    <Layout title={pageTitle} intro={pageIntro} pageContext={pageContext}>
      <Box>
        {allMd.edges.map(({ node }) => {
          const details = node.frontmatter
          const { fields } = node

          // TODO: differentiate between blog and portfolio items
          return (
            details.name && (
              <PortfolioItemLink
                key={details.name}
                details={details}
                fields={fields}
              />
            )
          )
        })}
      </Box>
    </Layout>
  )
}

// TODO: consider using mdx for blog posts in order to include things like map
// components or charts or other fanciness.
export const pageQuery = graphql`
  query AllBlogItems {
    allMd: allMarkdownRemark {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tags
            intro
            featured_image
          }
        }
      }
    }
  }
`

export default Blog
