import React from 'react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Box, Flex, Text, Button, Link } from 'rebass/styled-components'

import { convertToValidId } from '../helpers/helpers'
import Layout from '../components/layout'
import CircularIcon from '../components/layout-pieces/circular-icon'
import * as Icons from '../helpers/icons'
import TocGridded from '../components/layout-pieces/toc-gridded'
import LogoPinsOnly from '../images/logo/abm-logo-pins-only.svg'
import Modal from '../components/layout-pieces/modal'

const MadeByAbmIndicator = ({ iconSize = '1.5em' }) => (
  <Flex
    title="Indicates maps A Better Map has worked on"
    alignItems="center"
    sx={{ width: iconSize }}
    mr={2}
    as="span" // avoid error about <div> inside <p>
  >
    <Box as={LogoPinsOnly} sx={{ size: iconSize }} />
  </Flex>
)

const MadeByAbmDisclaimer = () => (
  <p
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    <MadeByAbmIndicator />
    <Text
      as="small"
      sx={{
        color: 'grays.8',
        fontStyle: 'italic',
        flex: 1,
      }}
    >
      A Better Map has had the priviledge of collaborating on several
      interesting web maps, indicated by this symbol.
    </Text>
  </p>
)

const FullBgIntro = ({
  'bg-image': bgImage,
  altText,
  heading,
  intro,
  icon,
}) => (
  <Box
    // Corresponds with <Container> padding
    mx={[-4, -6, -8, -10, -11]}
    mb={[8, null, 11]}
    height={['70vh', null, null, null, '65vh']}
    sx={{
      position: 'relative',
      color: 'white',
    }}
  >
    <Box
      as={BackgroundImage}
      fluid={bgImage.fluid}
      fadeIn={false}
      aria-label={altText}
      role="img"
      height="100%"
      style={{
        position: 'absolute',
        width: '100%',
      }}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        bottom: 0,
      }}
    />
    <FullBgIntroContent heading={heading} intro={intro} icon={icon} />
  </Box>
)

const FullBgIntroContent = ({ heading, intro, icon }) => (
  <Flex
    p={[4, null, 10, 12]}
    bg="hsla(0, 0%, 13%, 0.4)"
    height="100%"
    flexDirection="column"
    textAlign="center"
    justifyContent="center"
    sx={{
      gridArea: 'content',
      position: 'relative',
      zIndex: 1,
      textShadow: 'usesHeroes',
    }}
  >
    <CircularIcon icon={icon} />
    <Box
      as="h2"
      mt={4}
      style={{ textShadow: 'inherit', color: 'inherit', marginBottom: 0 }}
    >
      {heading}
    </Box>
    <p style={{ textShadow: 'inherit' }}>{intro}</p>
  </Flex>
)

// TODO: break out all example stuff into separate file
const UseExampleImg = ({ gatsbyImgInfo, caption, descripSrc }) => (
  <Box as="figure">
    <Box
      as={Img}
      fluid={gatsbyImgInfo.fluid}
      sx={{
        borderColor: 'grays.2',
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
    />
    <Text
      className="figcap-full-instr"
      style={{ width: '100%', textAlign: 'center' }}
    >
      (click image for full screen and caption)
    </Text>
    <figcaption className="the-caption-itself">
      {descripSrc && (
        <blockquote cite={descripSrc}>{`“${caption}”`}</blockquote>
      )}
      {!descripSrc && `“${caption}”`}
    </figcaption>
  </Box>
)

// TODO: use the unused vars
const UseExample = ({
  // alt_text, // TODO: implement
  descrip_src: descripSrc,
  description,
  is_abm: isAbm,
  name,
  owner,
  owner_descrip: ownerDescrip,
  url,
  screenshot,
}) => (
  <Box
    my={14}
    sx={{
      display: 'grid',
      gridColumnGap: [null, null, 8, 10],
      gridTemplateAreas: [
        '"header" "img" "copy"',
        null,
        `
        "img header"
        "img copy"
      `,
      ],
      gridTemplateColumns: [
        '1fr',
        null,
        '300px 1fr',
        'minmax(375px, 1fr) auto',
      ],
    }}
  >
    <Box
      as="header"
      mb={4}
      style={{
        gridArea: 'header',
      }}
    >
      <h4
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        {isAbm ? <MadeByAbmIndicator /> : null}
        {name}
      </h4>
      <small style={{ fontStyle: 'italic' }}>{owner}</small>
    </Box>
    <div style={{ gridArea: 'copy' }}>
      <p cite={descripSrc}>{description}</p>
      <div style={{ textAlign: 'center' }}>
        {isAbm ? (
          <Button color="white" as={GatsbyLink} to={url} title={name}>
            View this map
          </Button>
        ) : (
          <Button
            color="white"
            as={Link}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            title={name}
          >
            View this map
          </Button>
        )}
      </div>
    </div>
    <Modal
      origElemSx={{
        gridArea: 'img',
        cursor: 'pointer',
        '.figcap-full-instr': { display: 'block' },
        '.the-caption-itself': { display: 'none' },
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      modalChildSx={{
        mx: 6,
        maxWidth: screenshot.fluid.presentationWidth,
        figcaption: { color: 'white' }, // override the global gray
        '.the-caption-itself': { marginTop: 0 },
        '.figcap-full-instr': { display: 'none' },
      }}
    >
      <UseExampleImg
        gatsbyImgInfo={screenshot}
        caption={ownerDescrip}
        descripSrc={descripSrc}
      />
    </Modal>
  </Box>
)

const Use = ({ config, bgImage, examplesImgQuery }) => {
  const { intro, heading, icon, examples, alt_text: altText } = config
  const Icon = Icons[icon]

  return (
    <Box
      id={convertToValidId(heading)}
      // TODO: does <section> make sense here?
      as="section"
      my={12}
    >
      <FullBgIntro
        heading={heading}
        intro={intro}
        icon={Icon}
        bg-image={bgImage}
        altText={altText}
      />
      {examples.map(example => (
        <UseExample
          key={example.name}
          screenshot={
            examplesImgQuery.edges.find(
              ({ node }) => example.img === `${node.name + node.ext}`
            ).node.childImageSharp
          }
          {...example}
        />
      ))}
    </Box>
  )
}

const Uses = ({ pageContext, data }) => {
  const { frontmatter } = data.mdx
  const { edges } = data.usesIntrosBgs
  const examplesImgQuery = data.usesExamples
  const { uses, img_dir: relImgBaseDir } = frontmatter
  const pageTitle = 'Uses for interactive maps'
  const pageIntro =
    'From wildlife to water, recreation to ancient Rome, interactive maps span the spatial spectrum. Check out some fascinating examples of how web maps are used today.'

  return (
    <Layout title={pageTitle} intro={pageIntro} pageContext={pageContext}>
      <Box as={TocGridded} sx={{ my: 10 }} items={uses} />
      <MadeByAbmDisclaimer />
      {uses.map(use => {
        return (
          <Use
            key={use.icon}
            config={use}
            relImgBaseDir={relImgBaseDir}
            examplesImgQuery={examplesImgQuery}
            bgImage={
              edges.find(({ node }) => use.img === `${node.name + node.ext}`)
                .node.childImageSharp
            }
          />
        )
      })}
    </Layout>
  )
}

export const data = graphql`
  query IntMapUsesQuery {
    mdx(fields: { slug: { eq: "/uses-content" } }) {
      id
      frontmatter {
        uses {
          heading
          icon
          img
          intro
          short_name
          alt_text
          examples {
            alt_text
            descrip_src
            description
            img
            is_abm
            name
            owner
            owner_descrip
            url
          }
        }
      }
    }
    usesIntrosBgs: allFile(filter: { relativeDirectory: { eq: "uses" } }) {
      edges {
        node {
          name
          ext
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    usesExamples: allFile(
      filter: { relativeDirectory: { eq: "uses/examples-screenshots" } }
    ) {
      edges {
        node {
          name
          ext
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              presentationWidth
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Uses
