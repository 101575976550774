import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box } from 'rebass/styled-components'
import { hideVisually } from 'polished'

import LogoFull from '../../images/logo/abm-logo-orig-glacial-txt.svg'

const logoStyles = {
  '.abm-logo-text': {
    fill: 'icons.logo.text',
  },
}

const Logo = () => (
  <GatsbyLink to="/" style={{ height: '100%' }}>
    <span style={{ ...hideVisually() }}>A Better Map</span>
    <Box as="figure" mb="0" height="100%" sx={logoStyles}>
      <LogoFull />
    </Box>
  </GatsbyLink>
)

export default Logo
