import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
import { Link as GatsbyLink } from 'gatsby';
import BigCenteredBtn from '../../components/layout-pieces/big-centered-btn';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Standalone interactive web maps`}</h2>
    <p>{`A Better Map develops `}<em parentName="p">{`standalone web maps`}</em>{` such as the `}<GatsbyLink to="/portfolio/sleeping-bear-heritage-trail" mdxType="GatsbyLink">{`Sleeping Bear Heritage
Trail`}</GatsbyLink>{`. This type of map is more suited to live on its own rather
than inside an existing web page. Standalone maps offer a greater degree of map
capabilities and development freedom, so they make a great choice for more
complex projects.`}</p>
    <h2>{`Map integration into an existing website`}</h2>
    <p>{`In addition to standalone maps, we also develop interactive map projects that
make more sense `}<em parentName="p">{`inside an existing website`}</em>{`. Although similar to the concept of
standalone maps, integration may require other steps to bring a new map into
your existing setup. One benefit of integration includes being able to match the
rest of your site's style and overall message or theme.`}</p>
    <h2>{`Upgrades and conversions of existing maps`}</h2>
    <p>{`Do you `}<em parentName="p">{`already have a map`}</em>{` that you would simply like to upgrade? Whether it is
a PDF, image, or even a basic web map that just needs some love, we can
transform whatever you've got into something more interactive that your users
will appreciate.`}</p>
    <h2>{`WordPress map plugins`}</h2>
    <p>{`The most popular content management system on the web, `}<em parentName="p">{`WordPress`}</em>{` is used by `}<a href="https://w3techs.com/technologies/details/cm-wordpress" target="_blank" rel="noopener noreferrer" title="Usage statistics and market share of
WordPress">{`over 35% of the internet`}</a>{`. WordPress plugins open up a lot of
doors in terms of interactive map development, so if your site is using that
platform then we would be interested in developing a custom plugin for you.`}</p>
    <h2>{`Basic interactive maps`}</h2>
    <p>{`While we specialize in advanced interactive maps, they may be overkill for
smaller projects (and smaller budgets). That said, we also offer `}<em parentName="p">{`simpler
solutions`}</em>{` that do not require the time and coding found in larger projects. The
result is a more basic product with no way to query features, filter data, or dynamically display your map layers, but this may be all your project requires!`}</p>
    <BigCenteredBtn to="/contact" mdxType="BigCenteredBtn">Contact us</BigCenteredBtn>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      