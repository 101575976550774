import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Flex, Link } from 'rebass/styled-components'

import * as Icons from '../../helpers/icons'
import socialLinksConfig from '../../config/config.social-links'
import { footerLinks, footerLinksInspiration } from '../../config/config.nav'
import useFooterQuery from '../../queries/footer-query'
import Container from './container'
import SocialLinks from './social-links'

// Top-level-links
const FooterLinksList = ({ links, heading }) => (
  <Box pb={4} color="white">
    <h3 style={{ color: 'inherit' }}>{heading}</h3>
    <Box as="ul" variant="listUnstyled" className="gradiented-links">
      {links.map(({ text, to, title, nested }) => (
        <Box as="li" key={to} pl={nested && 6}>
          <Link
            as={GatsbyLink}
            to={to}
            title={title}
            className="gtm-link-footer"
          >
            {text}
          </Link>
        </Box>
      ))}
    </Box>
  </Box>
)

// Logo and copyright
const BizInfo = ({ tagline }) => {
  const { basicMap: BasicMapIcon } = Icons

  return (
    <Flex
      textAlign="center"
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        borderTopColor: 'footerLogoBndry',
        borderTopStyle: 'dashed',
        borderTopWidth: '1px',
        pt: [5, 6, 7],
      }}
    >
      <Box as={BasicMapIcon} height="4rem" width="4rem" mb={4} />
      <small>
        <b>{tagline}</b>
        <br />
        &copy; {new Date().getFullYear()} A Better Map, LLC
      </small>
    </Flex>
  )
}

const Footer = () => (
  <Box
    bg="footerBg"
    color="white"
    py={[5, 6, 7]}
    as="footer"
    mt={10}
    sx={{
      a: {
        color: 'footerLinks',
        // TODO: resolve the <a> chaos (<a>os??). Or keep dealing with it.
        backgroundImage:
          'linear-gradient(to bottom, transparent 0%, transparent 85%, hsla(137, 55%, 53%, 0.6) 85%, hsla(137, 55%, 53%, 0.6) 100%)',
      },
      'a:hover': {
        color: 'footerLinksHover',
      },
    }}
  >
    <Container maxWidth={[null, null, 'containers.crazyWide']}>
      <Box
        pb={8}
        sx={{
          display: [null, null, 'grid'],
          gridTemplateColumns: [null, null, '1fr 1fr', '1fr 1fr 1fr'],
          gridColumnGap: 10,
        }}
      >
        <FooterLinksList heading="Get your bearings" links={footerLinks} />
        <FooterLinksList
          heading="Get inspired"
          links={footerLinksInspiration}
        />
        <SocialLinks config={socialLinksConfig} />
      </Box>
      <BizInfo tagline={useFooterQuery().site.siteMetadata.tagline} />
    </Container>
  </Box>
)

export default Footer
