import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    // iPhone 5 and smaller
    font-size: ${p => p.theme.globalFontRems.default};
    
    // Try to fix overlap of top-bar when links are clicked
    // CRED: https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
    scroll-padding-top: ${p => p.theme.sizes.header.mobile};
    
    // Larger than iPhone 5
    @media screen and (min-width: ${p => p.theme.breakpoints[0]}) {
      font-size: ${p => p.theme.globalFontRems.sm};
    }

    // At least iPad portrait
    @media screen and (min-width: ${p => p.theme.breakpoints[1]}) {
      // NOTE: trying same font size as big phones for now
      // font-size: ${p => p.theme.globalFontRems.med};
      scroll-padding-top: ${p => p.theme.sizes.header.tabletAndUp};
    }
    
    // At least iPad landscape
    @media screen and (min-width: ${p => p.theme.breakpoints[2]}) {
      font-size: ${p => p.theme.globalFontRems.lg};
    }

    // Larger than my laptop
    @media screen and (min-width: ${p => p.theme.breakpoints[3]}) {
      font-size: ${p => p.theme.globalFontRems.xl};
    }
  }

  body {
    color: ${p => p.theme.colors.bodyText};
    font-family: 'Questrial', sans-serif;
    font-weight: ${p => p.theme.fontWeights.body};
    line-height: ${p => p.theme.lineHeights.body};
  }
  
  h1, h2, h3, h4, h5, h6 {
    // TODO: tighten up bottom margin SLIGHTLY when followed by a paragraph.
    // So basically set mb to 0 then +p selector to mt 5 or something.
    color: ${p => p.theme.colors.mainHeadingText};
    font-family: 'Raleway', sans-serif;
    font-weight: ${p => p.theme.fontWeights.heading};
    line-height: ${p => p.theme.lineHeights.heading};
    margin: ${p => p.theme.space[8]} 0 ${p => p.theme.space[4]};
  }

  h1 {
    margin-top: 0;
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.1rem;
  }  

  h3 {
    font-size: 1.8rem;
  }  

  h4 {
    font-size: 1.65rem;
  }  

  h5 {
    font-size: 1.5rem;
  }  

  .abm {
    pre, fieldset {
      margin: 0 0 ${p => p.theme.space[6]};
    }

    code {
      font-size: ${p => p.theme.fontSizes[2]};
    }
  }

  a {
    transition: 300ms;
  }

  main a {
    color: ${p => p.theme.colors.bodyText};
    transition: 300ms;
  }

  // NOTE: this is so gross and fragile but it's working for now.
  // TODO: some kind of hover to go with this
  .gradiented-links a, p > a {
    background-image: ${p =>
      `linear-gradient(to bottom, transparent 0%, transparent 85%, ${p.theme.colors.linkGradientBg} 85%, ${p.theme.colors.linkGradientBg} 100%)`}
  }

  small {
    font-size: ${p => p.theme.fontSizes[2]}
  }

  abbr[title] {
    text-decoration: none;
  }

  blockquote {
    font-style: italic;
    color: ${p => p.theme.colors.grays[5]};
    border-left: solid 3px ${p => p.theme.colors.grays[2]};
    padding-left: ${p => p.theme.space[5]};
    margin-left: ${p => p.theme.space[5]};
  }

  figure {
    color: ${p => p.theme.colors.grays[8]};
    font-size: ${p => p.theme.fontSizes[2]};
  }

  figcaption {
    margin-top: ${p => p.theme.fontSizes[2]};
  }

  // e.g. examples screenshots (would be nice in modal but late n lazy)
  figcaption blockquote {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  // Form elements
  fieldset label {
    cursor: pointer;
  }

  // Logo
  .abm-logo-icon {
    fill: ${p => p.theme.colors.icons.logo.icon};
  }

  // Tried css prop, sx, custom <Arrow>. This way was least painful.
  .slick-slider {
    .slick-arrow {
      border-radius: 100%;
      height: 2.5rem;
      width: 2.5rem;
      z-index: 2;
    }
    
    .slick-arrow:hover:before {
      color: ${p => p.theme.colors.primary[2]};
    }

    .slick-arrow:before {
      color: ${p => p.theme.colors.primary[1]};
      font-size: 2.5rem;
      transition: color 300ms;
      opacity: 0.85;
      display: block; // to fill up parent and make the whole thing clickable
    }
  }
`

export default GlobalStyle
