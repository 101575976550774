import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Flex, Text, Link } from 'rebass/styled-components'
import { hideVisually } from 'polished'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as Icons from '../../helpers/icons'

const SocialLinkListItem = ({ name, url, icon, handleClick, emailVisible }) => (
  <li>
    <Flex
      as={Link}
      href={url || 'javascript;'}
      title={`A Better Map ${name} profile page`}
      className="gtm-link-footer"
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        if (handleClick) {
          e.preventDefault()
          handleClick(!emailVisible)
        }
      }}
      style={{
        backgroundImage: 'none',
      }}
      alignItems="center"
      justifyContent="center"
      size="footerIcon"
      bg="socialLinkIconBg"
      sx={{
        borderRadius: '100%',
        ':hover': {
          bg: [null, null, null, 'socialLinkIconBgHover'],
        },
      }}
    >
      <Box size="75%" as={Icons[icon]} sx={{ fill: 'white' }} />
    </Flex>
  </li>
)

const SocialLinks = ({ config }) => {
  const [emailVisible, toggleEmail] = useState(false)
  const initialEmailText = '(click to copy)'
  const [emailCopiedText, setEmailCopiedText] = useState(initialEmailText)

  return (
    <Box pb={4}>
      <h3 style={{ color: 'inherit' }}>Get in touch</h3>
      <p>
        Got a project in mind? Don&apos;t be shy!{' '}
        <Link as={GatsbyLink} to="/contact" className="gtm-link-footer">
          Let us know
        </Link>{' '}
        or feel free to reach out on the interwebs:
      </p>
      <Text as="h4" sx={{ ...hideVisually() }}>
        Social media links
      </Text>
      <Flex as="ul" justifyContent="space-around" variant="listUnstyled">
        {config.map(({ name, url, icon }) => (
          <SocialLinkListItem
            key={icon}
            handleClick={!url && toggleEmail}
            emailVisible={!url && emailVisible}
            {...{ icon, name, url }}
          />
        ))}
      </Flex>
      {emailVisible && (
        <CopyToClipboard
          text="jason@abettermap.com"
          onCopy={() => {
            setEmailCopiedText('Copied!')
            window.setTimeout(() => setEmailCopiedText(initialEmailText), 5000)
          }}
        >
          <Box mt={8} as="p" textAlign="center" style={{ cursor: 'pointer' }}>
            <Text as="span" color="linkColor">
              jason@abettermap.com
            </Text>{' '}
            {emailCopiedText}
          </Box>
        </CopyToClipboard>
      )}
    </Box>
  )
}

export default SocialLinks
