/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
// TODO: import PropTypes from "prop-types"
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { Box } from 'rebass/styled-components'

import './global-styles.css'
import theme from '../config/theme'
import GlobalStyle from '../config/style.global'
import navItemsConfig from '../config/config.nav'
import SEO from './seo'
import Header from './layout-pieces/header'
import Footer from './layout-pieces/footer'
import MainContent from './layout-pieces/main-content'
import BackToTopBtn from './layout-pieces/back-to-top-btn'

const Layout = ({
  children,
  seoTitle = '',
  pageContext,
  title,
  intro,
  isHome = false,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <SEO title={seoTitle || title} />
      <GlobalStyle />
      <Box
        height="100vh"
        width="100vw"
        className="topo-bg"
        sx={{
          position: 'fixed',
          zIndex: 1,
        }}
      />
      <div
        className="abm"
        style={{
          zIndex: 2,
          position: 'relative',
        }}
      >
        <Header
          navItemsConfig={navItemsConfig}
          open={open}
          setOpen={setOpen}
          isHome={isHome}
        />
        <main>
          <article>
            {/* Crappy check that basically lets anything but Home slip thru */}
            {pageContext ? (
              <MainContent
                intro={intro}
                title={title}
                crumbs={pageContext && pageContext.breadcrumb.crumbs}
              >
                {children}
              </MainContent>
            ) : (
              children
            )}
          </article>
        </main>
        <Footer />
      </div>
      <BackToTopBtn />
    </ThemeProvider>
  )
}

export default Layout
