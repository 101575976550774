import React from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import PortfolioItemLink from '../components/layout-pieces/link-to-portfolio-item'

const Portfolio = ({ pageContext, data }) => {
  const { portfolioItems } = data
  const pageTitle = 'Map portfolio'
  const pageIntro =
    'A Better Map has produced several interactive maps for some great customers and partners in a variety of industries. Each client produces and controls their own content while A Better Map handles the programming, development, and just making sure the moving parts work together.'

  // TODO: emphasize somewhere or multiple places that the whole process is a
  // collaborative effort, not just a one-nerd show over here.

  return (
    <Layout title={pageTitle} intro={pageIntro} pageContext={pageContext}>
      <Box>
        {[...portfolioItems.edges]
          .sort(
            (a, b) =>
              a.node.frontmatter.sortOrder - b.node.frontmatter.sortOrder
          )
          .map(({ node }) => {
            const details = node.frontmatter

            // TODO: differentiate between blog and portfolio items
            return (
              details.name && (
                <PortfolioItemLink
                  key={details.name}
                  details={details}
                  fields={node.fields}
                />
              )
            )
          })}
      </Box>
    </Layout>
  )
}

// TODO: alias more snake_case to snakeCase directly in queries
// CRED: https://github.com/gatsbyjs/gatsby/issues/1634#issuecomment-318336985
export const pageQuery = graphql`
  query AllPortfolioItems {
    portfolioItems: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(portfolio)/.*\\.md$/"}}
    ) {
      edges {
        node {
          id
          frontmatter {
            customer
            feature_tags
            industry_tags
            intro
            logo
            featuredImg: featured_img
            sortOrder: sort_order
            name
            tech_tags
            location {
              latitude
              longitude
              name
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Portfolio
