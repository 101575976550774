import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Desktop GIS products`}</h2>
    <p>{`GIS is often the basis of interactive map projects, but we not provide
GIS-specific tools and applications beyond the context of web-related
requirements.`}</p>
    <h2>{`Data creation and maintenance`}</h2>
    <p>{`Your written content, images, and geographic data are typically your
responsibility, although we can certainly provide assistance with things like:`}</p>
    <ul>
      <li parentName="ul">{`One-off data conversion if it is not an ongoing project`}</li>
      <li parentName="ul">{`Guidance and suggestions for ongoing data management workflows`}</li>
      <li parentName="ul">{`Basic quality control and error checking`}</li>
    </ul>
    <h2>{`Full websites without a map focus`}</h2>
    <p>{`While we could probably create a website in our sleep, our passion and purpose
are to develop interactive maps and not full-fledged websites unrelated to
mapping. If that is your project's focus let us know and we can point you in A
Better Direction!`}</p>
    <h2>{`IT tasks and server administration`}</h2>
    <p>{`Some interactive map projects can thrive without ever uttering the word
"server", while others are far more complex than that. Due to the complexity
(and liability) of ongoing server maintenance, however, it is not something A
Better Map provides, although we can offer basic consultation for choosing and
using the right platform. We are also happy to clear up any confusion in terms
of what qualifies as "server maintenance" since it is quite a vast topic.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      