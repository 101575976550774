import React from 'react'
import { Box, Flex, Link } from 'rebass/styled-components'
import { Label, Input, Select, Radio, Checkbox, Textarea } from '@rebass/forms'

import ResponsiveGrid from '../extra-generic/responsive-grid'

const MapDetails = ({ register }) => (
  <section>
    <h2>Map details</h2>
    <p>
      We'd like a little background info about what you are looking for as well
      as your current map situation. Please fill out anything you feel is
      relevant to help move our conversation along.
    </p>
    <h4>The basics</h4>
    <ResponsiveGrid numDeskCols={2}>
      <fieldset>
        <legend>Current map situation</legend>
        <Box as="ul" variant="listUnstyled">
          <li>
            <Flex as={Label} htmlFor="no-map">
              <Radio
                id="no-map"
                name="current-map-situation"
                defaultChecked
                ref={register}
                value="None"
              />
              None
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="static-only">
              <Radio
                id="static-only"
                name="current-map-situation"
                ref={register}
                value="Non-interactive (PDF, JPG, etc.)"
              />
              Non-interactive (PDF, JPG, etc.)
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="basic-interactive">
              <Radio
                id="basic-interactive"
                name="current-map-situation"
                ref={register}
                value="Interactive but needs love"
              />
              Interactive but needs love
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="other-map">
              <Radio
                id="other-map"
                name="current-map-situation"
                ref={register}
                value="Other type of map"
              />
              Other type of map
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="some-combo">
              <Radio
                id="some-combo"
                name="current-map-situation"
                ref={register}
                value="Some combination of these"
              />
              Some combination of these
            </Flex>
          </li>
        </Box>
      </fieldset>
      <Box mb={6}>
        <Label htmlFor="product-type">
          <span>Type of map you are looking for</span>
        </Label>
        <Select id="product-type" name="product-type" ref={register}>
          <option value="standalone">Standalone website</option>
          <option value="native-app">iOS/Android app</option>
          <option value="wp-plugin">WordPress plugin</option>
          <option value="simple-google">Basic (Google Map or similar)</option>
          <option value="consultation-only">Consultation only</option>
          <option value="other">Other product</option>
          <option value="multiple">Multiple products</option>
          <option value="unsure">Not sure</option>
        </Select>
      </Box>
    </ResponsiveGrid>
    <fieldset>
      <legend>Potential enhancements (check all that apply)</legend>
      <Box as="ul" variant="listUnstyled">
        <li>
          <Flex as={Label} htmlFor="GPS">
            <Checkbox id="GPS" name="fancy-options-gps" ref={register} />
            Geolocation/GPS
          </Flex>
        </li>
        <li>
          <Flex as={Label} htmlFor="offline">
            <Checkbox
              id="offline"
              name="fancy-options-offline"
              ref={register}
            />
            Offline-ready (no cell, no Wifi)
          </Flex>
        </li>
        <li>
          <Flex as={Label} htmlFor="charts-or-graphs">
            <Checkbox
              id="charts-or-graphs"
              name="fancy-options-charts"
              ref={register}
            />
            Charts/graphs, data visualization
          </Flex>
        </li>
        <li>
          <Flex as={Label} htmlFor="direct-sharing">
            <Checkbox
              id="direct-sharing"
              name="fancy-options-sharing"
              ref={register}
            />
            Direct URL sharing
          </Flex>
        </li>
        <li>
          <Flex as={Label} htmlFor="data-collection">
            <Checkbox
              id="data-collection"
              name="fancy-options-data-collect"
              ref={register}
            />
            Data collection/uploading
          </Flex>
        </li>
        <li>
          <Flex as={Label} htmlFor="integrations">
            <Checkbox
              id="integrations"
              name="fancy-options-integrations"
              ref={register}
            />
            3rd-party integrations (tweets, weather, traffic, etc.)
          </Flex>
        </li>
      </Box>
    </fieldset>
    <p>
      <Label htmlFor="map-comments">
        <span>Summary of project or request</span>
      </Label>
      <Textarea
        id="map-comments"
        name="map-comments"
        rows="5"
        placeholder="Describe your goals for this map. What do you need help with?"
        ref={register}
      />
    </p>
    <h4>Examples of existing maps</h4>
    <p>
      If you have any examples of web maps or apps that you are fond of and
      potentially useful to your project, please include them below. If not, get
      some inspiration from{' '}
      <Link href="/portfolio" title="Company portfolio" target="_blank">
        one of our maps
      </Link>{' '}
      or see{' '}
      <Link
        href="/uses-for-interactive-maps"
        title="Uses for interactive maps"
        target="_blank"
      >
        how others use interactive maps
      </Link>{' '}
      (both links open in a new tab so you won&apos;t lose your progress on this
      form).
    </p>
    <ResponsiveGrid numDeskCols={2}>
      <p>
        <Label htmlFor="existing-example-1">
          <span>Example 1</span>
        </Label>
        <Input
          type="text"
          id="existing-example-1"
          name="existing-example-1"
          placeholder="Example map URL 1"
          ref={register}
        />
      </p>
      <p>
        <Label htmlFor="existing-example-2">
          <span>Example 2</span>
        </Label>
        <Input
          type="text"
          id="existing-example-2"
          name="existing-example-2"
          placeholder="Example map URL 2"
          ref={register}
        />
      </p>
    </ResponsiveGrid>
    <h4>Data/content info</h4>
    <ResponsiveGrid numDeskCols={2}>
      <fieldset>
        <legend>Data/content updates</legend>
        <Box as="ul" variant="listUnstyled">
          <li>
            <Label htmlFor="update-owner-once">
              <Radio
                id="update-owner-once"
                name="data-content-updates"
                value="owner, once"
                defaultChecked
                ref={register}
              />
              Map owner, one-off
            </Label>
          </li>
          <li>
            <Label htmlFor="update-owner-ongoing">
              <Radio
                id="update-owner-ongoing"
                name="data-content-updates"
                value="owner, ongoing"
                ref={register}
              />
              Map owner, ongoing
            </Label>
          </li>
          <li>
            <Label htmlFor="update-abm-once">
              <Radio
                id="update-abm-once"
                name="data-content-updates"
                value="abm, once"
                ref={register}
              />
              A Better Map, once
            </Label>
          </li>
          <li>
            <Label htmlFor="update-abm-occasional">
              <Radio
                id="update-abm-occasional"
                name="data-content-updates"
                value="abm, occasional"
                ref={register}
              />
              A Better Map, occasionally
            </Label>
          </li>
          <li>
            <Label htmlFor="update-unsure">
              <Radio
                id="update-unsure"
                name="data-content-updates"
                value="abm, not sure"
                ref={register}
              />
              No idea!
            </Label>
          </li>
        </Box>
      </fieldset>
      <fieldset>
        <legend>Data types (check all that apply)</legend>
        <Box as="ul" variant="listUnstyled">
          <li>
            <Flex as={Label} htmlFor="data-gis-desktop">
              <Checkbox
                ref={register}
                id="data-gis-desktop"
                name="data-types-desktop"
              />
              Desktop GIS (Shapefiles, GDBs)
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="data-gis-server">
              <Checkbox
                ref={register}
                id="data-gis-server"
                name="data-types-server"
              />
              Server GIS (e.g. ArcGIS Enterprise)
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="data-spreadsheet">
              <Checkbox
                ref={register}
                id="data-spreadsheet"
                name="data-types-spreadsheet"
              />
              Spreadsheets
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="data-paper">
              <Checkbox
                ref={register}
                id="data-paper"
                name="data-types-paper"
              />
              Paper/notes
            </Flex>
          </li>
          <li>
            <Flex as={Label} htmlFor="data-other">
              <Checkbox
                ref={register}
                id="data-other"
                name="data-types-other"
              />
              Other
            </Flex>
          </li>
        </Box>
      </fieldset>
    </ResponsiveGrid>
    <p>
      <Label htmlFor="workflow-description">
        <span>Summary of your data management workflow</span>
      </Label>
      <Textarea
        id="workflow-description"
        name="workflow-description"
        rows="5"
        placeholder="If possible, describe the general workflow of how your map data, content, and media are or will be stored and edited."
        ref={register}
      />
    </p>
  </section>
)

export default MapDetails
