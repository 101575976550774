// CRED: https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
import React from 'react'
import { bool, func } from 'prop-types'

import StyledBurger from '../../config/style.burger'

const Burger = ({ open, setOpen }) => (
  <StyledBurger
    aria-label="Open Navigation Menu"
    open={open}
    onClick={() => setOpen(!open)}
  >
    <div />
    <div />
    <div />
  </StyledBurger>
)

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

export default Burger
