import React from 'react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex } from 'rebass/styled-components'

import { reasonsForIntMaps, reasonsForAbm } from '../content/home-content'
import Layout from '../components/layout'
import Showcase from '../components/layout-pieces/showcase-item'
import HomeIntro from '../components/layout-pieces/home-intro'
import { Tro } from '../components/layout-pieces/Extree'
import BigCenteredBtn from '../components/layout-pieces/big-centered-btn'

// TODO: break this out
const Customers = ({ data }) => (
  <Box
    maxWidth={[null, null, 'containers.thatsRightBigFella']}
    mt={11}
    sx={{
      display: [null, null, 'grid'],
      gridTemplateColumns: [null, null, '1fr 1fr 1fr'],
      gridColumnGap: 10,
    }}
  >
    {[
      { name: 'sclt', slug: 'sheridan-community-land-trust' },
      { name: 'sbht', slug: 'sleeping-bear-heritage-trail' },
      { name: 'partition', slug: '1947-partition-archive' },
    ].map(({ name, slug }) => (
      <Box
        key={name}
        as={GatsbyLink}
        to={`/portfolio/${slug}`}
        height="100%"
        style={{
          backgroundImage: 'none', // override the gradient
        }}
      >
        <span
          style={{ position: 'absolute', width: 0, height: 0, left: -9999 }}
        >{`${name} portfolio page`}</span>
        <Flex height="100%" mb={[10, null, 'unset']}>
          <Img
            imgStyle={{
              objectFit: 'contain',
            }}
            style={{
              display: 'flex',
              flex: 1,
              margin: '0 auto',
              maxWidth: data[name].childImageSharp.fluid.presentationWidth,
            }}
            fluid={data[name].childImageSharp.fluid}
          />
        </Flex>
      </Box>
    ))}
  </Box>
)

const HomePage = ({ data }) => (
  <Layout seoTitle="A Better Map - Interactive Web Maps" isHome>
    <HomeIntro />
    <Tro
      heading="What interactive maps can do for you"
      id="what-web-maps-can-do-for-you"
    >
      <p>
        Web maps are a dynamic modern tool with a long list of advantages, and
        interesting maps mean interested users.
      </p>
    </Tro>
    <Showcase items={reasonsForIntMaps} />
    <Tro heading="Why A Better Map?" id="outtro">
      <p>
        We have a diverse skillset that combines web programming and GIS. To
        learn more about what we do, check out our{' '}
        <GatsbyLink
          to="/products-and-services"
          title="Map products and services we provide"
        >
          products and services
        </GatsbyLink>
        .
      </p>
    </Tro>
    <Showcase items={reasonsForAbm} />
    <Tro heading="Who we've worked with" id="porttro">
      <p>
        A Better Map has worked with several great customers in a variety of
        industries.
      </p>
      <Customers data={data} />
    </Tro>
    <BigCenteredBtn to="/contact">Contact us today</BigCenteredBtn>
  </Layout>
)

export const data = graphql`
  fragment fragmentInner on File {
    name
    childImageSharp {
      fluid(maxWidth: 300, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
        presentationWidth
      }
    }
  }

  query PortLogosQuery {
    sbht: file(name: { eq: "sbht-logo-no-text" }) {
      ...fragmentInner
    }
    sclt: file(name: { eq: "sclt-logo" }) {
      ...fragmentInner
    }
    partition: file(name: { eq: "1947-logo" }) {
      ...fragmentInner
    }
  }
`

export default HomePage
