import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Flex, Text, Link } from 'rebass/styled-components'

import TagsList from './portfolio-tags-list'
import DynamicImage from './dynamic-img'

// TODO: consider fancier media card: https://codepen.io/nsatee/pen/jmyMpE
const LinkToPortfolioItem = ({ details, fields }) => (
  <Box
    mb={[8, null, 6]}
    py="4"
    as="section"
    sx={{
      display: [null, null, 'grid'],
      gridTemplateColumns: [
        null,
        null,
        'minmax(300px, auto) 1fr',
        'minmax(350px, auto) 1fr',
      ],
      gridColumnGap: [null, null, 2, 4],
      gridTemplateAreas: [null, null, '"logo text"'],
    }}
  >
    <Flex
      flexDirection="column"
      justifyContent="center"
      mb={[8, null, 6]}
      mx={[-6, null, 'unset']}
      style={{ gridArea: 'logo', bg: 'primary' }}
    >
      <GatsbyLink to={`/portfolio/${fields.slug}`}>
        <DynamicImage imgName={details.featuredImg} />
      </GatsbyLink>
    </Flex>
    <Box px={[null, null, 6]} sx={{ gridArea: 'text', bg: 'secondary' }}>
      <header>
        <h3>
          <GatsbyLink to={`/portfolio/${fields.slug}`}>
            {details.name}
          </GatsbyLink>
        </h3>
        <Text as="i" color="grays.7" display="block" mt="-0.7rem" fontSize={2}>
          {details.customer}
        </Text>
        {/* TODO: componentize the comprehensive tags list */}
        <Flex flexWrap="wrap" mb={4}>
          <Box as="ul" variant="listUnstyled">
            {details.industry_tags.map(tag => (
              <TagsList tag={tag} key={tag} type="industry" />
            ))}
          </Box>
          <Box as="ul" variant="listUnstyled">
            {details.feature_tags.map(tag => (
              <TagsList tag={tag} key={tag} type="feature" />
            ))}
          </Box>
        </Flex>
      </header>
      <p>
        {details.intro}{' '}
        <Link as={GatsbyLink} to={`/portfolio/${fields.slug}`}>
          <b>Visit project</b>
        </Link>
      </p>
    </Box>
  </Box>
)

export default LinkToPortfolioItem
