import React from 'react'
import { Box, Flex } from 'rebass/styled-components'

const CircularIcon = ({ icon }) => (
  <Flex justifyContent="center">
    <Flex
      size="5.5rem"
      bg="icons.usesIntros.bg"
      sx={{
        borderColor: 'icons.usesIntros.border',
        borderRadius: '100%',
        borderStyle: 'solid',
        borderWidth: 4,
        opacity: 0.85,
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        as={icon}
        size="3.5rem"
        sx={{
          fill: 'icons.usesIntros.icon',
        }}
        style={{ gridArea: 'icon' }}
      />
    </Flex>
  </Flex>
)

export default CircularIcon
