import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex, Link } from 'rebass/styled-components'

import mainReasons, { lessImportant } from '../content/reasons-content'
import Layout from '../components/layout'
import MainContent from '../components/layout-pieces/main-content'
import * as Icons from '../helpers/icons'

const JustTheTextMaam = ({ text, list, independent }) => (
  <Box px={!independent && [(null, null, 4)]}>
    <p>{text}</p>
    <ul style={{ marginBottom: 0 }}>
      {list.map(listItemText => (
        <li
          className="gradiented-links"
          key={listItemText}
          dangerouslySetInnerHTML={{ __html: listItemText }}
        />
      ))}
    </ul>
  </Box>
)

const AdvantageIcon = ({ icon, size, independent }) => (
  <Flex
    size={`${size}rem`}
    alignItems="center"
    justifyContent="center"
    mx={!independent && 'auto'}
    mt={!independent && [-8, null, 'unset']}
    bg="primary.0"
    sx={{ borderRadius: '100%', position: 'relative', zIndex: 2 }}
  >
    <Box as={icon} size={`${size * 0.55}rem`} sx={{ fill: 'white' }} />
  </Flex>
)

const AdvantageImage = ({ fluid }) => (
  <Box
    as={Img}
    fluid={fluid}
    mx={[-4, -6, 'unset']}
    sx={{
      color: 'advantagesImgShadow',
      boxShadow: '10px 7px 12px 0 currentColor',
    }}
  >
    <Img
      fluid={fluid}
      imgStyle={{ objectFit: 'contain' }}
      style={{ maxWidth: fluid.presentationWidth }}
    />
  </Box>
)

const AdvantageHeader = ({
  heading,
  subheading,
  align = 'center',
  headingFontSize,
  children,
}) => (
  <Flex
    as="header"
    color="white"
    style={{ position: 'relative' }}
    flexDirection="column"
    justifyContent="space-around"
    py={6}
    textAlign={align}
  >
    <Box as="h3" mb={4} fontSize={headingFontSize}>
      {heading}
    </Box>
    <Box as="p" px={align === 'center' && 7} color="grays.8">
      <span dangerouslySetInnerHTML={{ __html: subheading }} />
      {children}
    </Box>
  </Flex>
)

const Advantage = ({
  // imgCaption, // TODO: remove here and in src if not using
  children,
  independent,
}) => (
  <Box
    className="advantage-section"
    my={[14, null, 12, 14]}
    sx={{
      display: [null, null, 'grid'],
      alignItems: !independent && 'center',
      gridTemplateColumns: [null, null, '1fr 1fr'],
      gridColumnGap: 10,
      gridRowGap: 10,
      '> *': {
        mt: independent && 10,
      },
    }}
  >
    {children}
  </Box>
)

const ToggleableBody = ({ text, list }) => {
  const [textOpen, setTextOpen] = useState(false)

  return (
    <>
      <Link
        href="javascript;"
        textAlign="right"
        color="linkColor"
        ml={4}
        style={{
          backgroundImage: 'none',
        }}
        sx={{ display: ['block', null, 'initial'] }}
        onClick={e => {
          e.preventDefault()
          setTextOpen(!textOpen)
        }}
      >
        Read {textOpen ? 'less' : 'more'}...
      </Link>
      <Box
        color="bodyText"
        style={{
          height: 'auto',
          maxHeight: textOpen ? 3000 : 0,
          overflowY: !textOpen ? 'hidden' : 'auto',
        }}
      >
        <JustTheTextMaam {...{ text, list }} />
      </Box>
    </>
  )
}

const MehdvantageGroup = ({ items }) => {
  return (
    <Advantage independent>
      {items.map(({ heading, icon, subheading, list, text }) => (
        <div key={heading}>
          <AdvantageIcon independent icon={Icons[icon]} size={3} />
          <Box as="header" color="white" style={{ position: 'relative' }}>
            <AdvantageHeader
              headingFontSize={4}
              align="left"
              {...{ heading, subheading }}
            >
              <ToggleableBody {...{ text, list }} />
            </AdvantageHeader>
          </Box>
        </div>
      ))}
    </Advantage>
  )
}

const AdvantagesGroup = ({ items, images }) => (
  <Box
    // Alternate left/right position of pic/text
    sx={{
      '.advantage-section:nth-of-type(even) > :first-child': {
        order: 2,
      },
      '.advantage-section:nth-of-type(even) > :nth-child(2)': {
        order: 1,
      },
    }}
  >
    {items.map(({ heading, icon, subheading, list, img, text }) => (
      <Advantage key={heading}>
        <AdvantageImage
          fluid={
            images.find(({ node }) => img === `${node.name + node.ext}`).node
              .childImageSharp.fluid
          }
        />
        <div>
          <AdvantageIcon icon={Icons[icon]} size={3.8} />
          <AdvantageHeader {...{ heading, subheading }} />
          <JustTheTextMaam {...{ text, list }} />
        </div>
      </Advantage>
    ))}
  </Box>
)

const Page = ({ pageContext, data }) => {
  const pageTitle = 'Advantages of interactive maps'
  const pageIntro =
    'Interactive maps offer a modern alternative to traditional maps, provide solutions to typical map constraints, and deliver additional benefits.'
  const images = data.reasonsImages.edges

  return (
    <Layout title={pageTitle}>
      <MainContent
        intro={pageIntro}
        title={pageTitle}
        crumbs={pageContext.breadcrumb.crumbs}
      >
        <AdvantagesGroup items={mainReasons} images={images} />
        <MehdvantageGroup items={lessImportant} />
      </MainContent>
    </Layout>
  )
}

export const data = graphql`
  query MapReasonsQuery {
    reasonsImages: allFile(
      filter: { relativeDirectory: { eq: "reasons-to-use" } }
    ) {
      edges {
        node {
          name
          ext
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default Page
