import React from 'react'
import { Box, Flex, Link } from 'rebass/styled-components'

import { convertToValidId } from '../../helpers/helpers'
import * as Icons from '../../helpers/icons'

const TocItem = ({ heading, icon, shortName }) => (
  <Flex
    as="li"
    flexDirection="column"
    alignItems="space-between"
    justifyContent="space-around"
    textAlign="center"
  >
    <Flex
      px={5}
      as={Link}
      href={`#${convertToValidId(heading)}`}
      flexDirection="column"
      bg="usesTocBgColor"
      alignItems="center"
      justifyContent="center"
      title={heading}
      lineHeight="heading"
      height="100%"
      className="immune-to-override"
      sx={{
        borderRadius: 2,
        transition: '300ms background-color',
        ':hover': {
          bg: [null, null, null, 'usesTocBgColorHover'],
        },
        ':visited': {
          color: 'white',
        },
      }}
    >
      <Box
        as={icon}
        sx={{
          fill: 'currentColor',
          size: ['2rem', null, '3rem', '3.5rem'],
          mb: 4,
        }}
      />
      {shortName}
    </Flex>
  </Flex>
)

// CRED: ✨ Total magic ✨ https://medium.com/cloudaper/how-to-create-a-flexible-square-grid-with-css-grid-layout-ea48baf038f3
const TocGridded = ({ items, sx }) => (
  <Box as="nav" sx={sx}>
    <Box
      as="ul"
      variant="listUnstyled"
      sx={{
        display: 'grid',
        gridColumnGap: [2, 2, 3, 6, 8], // `null` to skip phablet not working
        gridRowGap: [4, 5, null, 3, 4],
        // TODO: figure out how to make it not suck for tweeners
        gridTemplateColumns: [
          'repeat(auto-fill, minmax(5rem, 1fr))',
          'repeat(auto-fill, minmax(5.5rem, 1fr))',
          `repeat(${items.length}, minmax(5rem, 2fr))`,
        ],
        gridAutoRows: '1fr',
        gridAutoColumns: '1px',
        a: {
          color: 'white',
          fontSize: ['0.85rem', null, 2],
        },
        '> *:first-child': {
          gridRow: '1 / 1',
          gridColumn: '1 / 1',
        },
      }}
      css={`
        :before {
          content: '';
          width: 0;
          padding-bottom: 100%;
          grid-row: 1 / 1;
          grid-column: 1 / 1;
        },
      `}
    >
      {items.map(use => {
        const { heading, icon, short_name: shortName } = use
        const NavIcon = Icons[icon]

        return (
          <TocItem
            key={use.icon}
            heading={heading}
            icon={NavIcon}
            shortName={shortName}
          />
        )
      })}
    </Box>
  </Box>
)

export default TocGridded
