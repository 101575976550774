import React from 'react'
import { Box } from 'rebass/styled-components'
import { Label, Input, Select, Radio } from '@rebass/forms'

import ResponsiveGrid from '../extra-generic/responsive-grid'

const ContactPrefs = ({ register }) => (
  <ResponsiveGrid numDeskCols={2}>
    <fieldset>
      <legend>Preferred contact method</legend>
      <Box as="ul" variant="listUnstyled">
        <li>
          <Label htmlFor="pref-any">
            <Radio
              id="pref-any"
              name="pref-contact-method"
              value="any"
              defaultChecked
              ref={register}
            />
            Any
          </Label>
        </li>
        <li>
          <Label htmlFor="pref-email">
            <Radio
              id="pref-email"
              name="pref-contact-method"
              value="email"
              ref={register}
            />
            Email
          </Label>
        </li>
        <li>
          <Label htmlFor="pref-phone">
            <Radio
              id="pref-phone"
              name="pref-contact-method"
              value="phone"
              ref={register}
            />
            Phone
          </Label>
        </li>
      </Box>
    </fieldset>
    <fieldset>
      <legend>Preferred time to contact</legend>
      <Box as="ul" variant="listUnstyled">
        <li>
          <Label htmlFor="pref-time-any">
            <Radio
              id="pref-time-any"
              name="pref-contact-time"
              value="Any"
              defaultChecked
              ref={register}
            />
            Any
          </Label>
        </li>
        <li>
          <Label htmlFor="pref-time-morning">
            <Radio
              id="pref-time-morning"
              name="pref-contact-time"
              value="Morning"
              ref={register}
            />
            Morning
          </Label>
        </li>
        <li>
          <Label>
            <Radio
              id="pref-time-afternoon"
              name="pref-contact-time"
              value="Afternoon"
              ref={register}
            />
            Afternoon
          </Label>
        </li>
        <li>
          <Label htmlFor="pref-time-evening">
            <Radio
              id="pref-time-evening"
              name="pref-contact-time"
              value="Evening"
              ref={register}
            />
            Evening
          </Label>
        </li>
      </Box>
    </fieldset>
  </ResponsiveGrid>
)
const ContactInfo = ({ register, includeDeets }) => (
  <Box as="section" sx={{ p: { mt: 0 } }}>
    <h2>Your contact info</h2>
    <ResponsiveGrid>
      <p>
        <Label htmlFor="name">
          <span>Name</span>
          <strong>
            <abbr title="required">*</abbr>
          </strong>
        </Label>
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="Your name"
          required
          ref={register}
        />
      </p>
      <p>
        <Label htmlFor="email">
          <span>E-mail</span>
          <strong>
            <abbr title="required">*</abbr>
          </strong>
        </Label>
        <Input
          type="email"
          id="email"
          name="email"
          placeholder="Valid email address"
          required
          ref={register}
        />
      </p>
      <p>
        <Label htmlFor="phone-number">
          <span>Phone</span>
        </Label>
        <Input
          type="tel"
          id="phone-number"
          name="phone-number"
          placeholder="Best contact number"
          ref={register}
        />
      </p>
      {includeDeets && (
        <>
          <p>
            <Label htmlFor="role-or-title">
              <span>Title</span>
            </Label>
            <Input
              type="text"
              id="role-or-title"
              name="role-or-title"
              placeholder="Your role or position"
              ref={register}
            />
          </p>
          <p>
            <Label htmlFor="organization">
              <span>Organization</span>
            </Label>
            <Input
              type="text"
              id="organization"
              name="organization"
              placeholder="Business or entity you represent"
              ref={register}
            />
          </p>
          <Box mb={6}>
            <Label htmlFor="industry-type">
              <span>Industry or discipline</span>
            </Label>
            <Select id="industry-type" name="industry-type" ref={register}>
              <option value="recreation-tourism">Recreation/tourism</option>
              <option value="history">History</option>
              <option value="natural-resources">Natural resources</option>
              <option value="conservation">Conservation</option>
              <option value="other">Other</option>
            </Select>
          </Box>
        </>
      )}
    </ResponsiveGrid>
    {includeDeets && <ContactPrefs register={register} />}
  </Box>
)

export default ContactInfo
