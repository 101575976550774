import React from 'react'

import Layout from '../layout'

export default function Template({ pageContext, children }) {
  const { frontmatter } = pageContext
  const { title, intro } = frontmatter

  return (
    <Layout title={title} intro={intro} pageContext={pageContext}>
      {children}
    </Layout>
  )
}
