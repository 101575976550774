import React from 'react'
import { Box, Flex } from 'rebass/styled-components'

import NavLink from './nav-link'
import Logo from './logo'
import Burger from './burger'

class Header extends React.Component {
  wrap = React.createRef()

  constructor(props) {
    super(props)
    this._isMounted = false

    this.state = {
      bgAlpha: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  // Increase background opacity on scrolling down
  handleScroll = () => {
    const currentVertPos = window.scrollY
    const quarterAvailVert = window.screen.height / 4
    const bgAlpha = Math.min(currentVertPos / quarterAvailVert, 1).toString()

    // CRED: https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component
    // CRED: https://stackoverflow.com/a/52061655/1048518
    this._isMounted && this.setState({ bgAlpha })
  }

  render() {
    const { navItemsConfig, open, setOpen, isHome } = this.props
    const { bgAlpha } = this.state

    const navMenuStyle = {
      bg: ['mobileNavBg', null, null, 'initial'],
      borderColor: 'secondary.1',
      borderStyle: ['solid none', null, null, 'unset'],
      borderWidth: [9, null, null, 'unset'],
      display: ['none', null, null, 'flex'],
      height: ['100%', null, null, 'auto'],
      left: [0, null, null, 'auto'],
      position: ['fixed', null, null, 'static'],
      top: [0, null, null, 'auto'],
      transitionDuration: `${open ? '0.6s' : '0.25s'}`,
      transitionTimingFunction: 'ease',
      transitionProperty: 'transform',
      width: ['100%', null, null, 'auto'],
      transform: [
        `${open ? 'translateX(0)' : 'translateX(-100vw)'}`,
        null,
        null,
        'none',
      ],
      'li:not(:last-child)': {
        borderBottom: ['solid 1px rgba(255,255,255,0.2)', null, null, 'unset'],
      },
    }
    const headerBgStyle = {
      position: 'relative',
      // Pseudo element allows for fading of BG opacity
      '::after': {
        opacity: !isHome ? 1 : bgAlpha,
        content: "''",
        transition: 'opacity 0.1s',
        bg: 'topBarBg',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: -1,
      },
    }
    const navLinks = navItemsConfig.map(navItem => {
      const { text, to, title, isButton } = navItem

      return <NavLink key={to} {...{ text, to, title, isButton }} />
    })

    // TODO: break some of this mess out
    return (
      <Box
        as="header"
        color="white"
        height={['header.mobile', null, 'header.tabletAndUp']}
        style={{ top: 0, left: 0, position: 'sticky', zIndex: 10 }}
        ref={this.wrap}
        sx={headerBgStyle}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={[4, null, 6]}
          mx="auto"
          height="100%"
          maxWidth={[
            null,
            null,
            'containers.tabletLand',
            'containers.crazyWide',
          ]}
        >
          <Logo />
          <nav>
            {/* On/Off-canvas nav menu */}
            <Flex
              as="ul"
              variant="listUnstyled"
              justifyContent={['center', null, null, 'space-around']}
              flexDirection={['column', null, null, 'row']}
              sx={navMenuStyle}
            >
              {navLinks}
            </Flex>
          </nav>
          <Burger open={open} setOpen={setOpen} />
        </Flex>
      </Box>
    )
  }
}

export default Header
