export const reasonsForIntMaps = [
  {
    heading: 'Keep your users interested',
    body:
      "Interactive maps satisfy short attention spans, and engaged users help you reach your map's mission.",
    icon: 'interactive',
    btnText: 'See the benefits',
    btnLink: '/advantages-of-interactive-maps',
  },
  {
    heading: "There's a map for that",
    body:
      'From wildlife to water, recreation to ancient Rome, web maps span a versatile spatial spectrum.',
    icon: 'versatile',
    btnText: 'See some examples',
    btnLink: '/uses-for-interactive-maps',
  },
  {
    heading: 'Tech it up a notch',
    body:
      'Web maps excel where PDF features fall short including GPS, direct links, and interactive graphs.',
    icon: 'currentLocation',
    btnText: 'Stay modern',
    btnLink: '/advantages-of-interactive-maps',
  },
]

export const reasonsForAbm = [
  {
    heading: 'Programming + GIS = A Better Map',
    body:
      "Need to tackle the legwork of GIS, programming, and all points in between? You've come to the right place.",
    icon: 'interactive',
    btnText: 'Why to choose us',
    btnLink: '/products-and-services/why-choose-a-better-map',
  },
  {
    heading: 'Your project, your style',
    body:
      'Web maps are as unique as the projects they support, so we focus on what works for you and your message.',
    icon: 'customized',
    btnText: 'Get customized',
    btnLink: '/products-and-services',
  },
]
