import React from 'react'
import { Button, Link } from 'rebass/styled-components'

import Layout from '../components/layout'
import ColorSchemeDemo from '../components/color-scheme-demo'
import FancyHr from '../components/layout-pieces/horizontal-rule'

const fakeCode = `<heyman>
  <HowDidYou>
    Find this place???
  </HowDidYou>
</heyman>`

const SuperSecretStyleGuide = () => (
  <Layout
    title="Super Secret StyleGuide"
    intro="Looking stylish 😎. Minus the stubby intro."
    pageContext={{ breadcrumb: [] }}
  >
    <h1>Heading level 1</h1>
    <h2>Heading level 2</h2>
    <h3>Heading level 3</h3>
    <h4>Heading level 4</h4>
    <h5>Heading level 5</h5>
    <h2>Buttons</h2>
    <Button>Regs Btn</Button>
    <Button variant="hollow">Hollow Btn</Button>
    <h2>Colors</h2>
    <ColorSchemeDemo />
    <h2>Text</h2>
    <p>
      This is a paragraph from hipster ipsum four loko pitchfork quinoa, VHS
      kogi seitan lomo scenester. Deep v pour-over tote bag. Vaporware
      scenester, health bun banh mi try-hard meh semiotics salvia. YOLO squid
      four loko sriracha butcher snackwave health goth wolf. Tumeric artisan
      jianbing, godard PBR leggings vinyl.
    </p>
    <p>
      <b>This is supposedly bold text.</b>
    </p>
    <p>
      <i>This is italic text.</i>
    </p>
    <p>
      This is{' '}
      <Link href="#fake-tro" title="Your target, your fault">
        a hyperlink
      </Link>{' '}
      to nowhere.
    </p>
    <h2>Lists</h2>
    <h3>Ordered</h3>
    <ol>
      <li>Ok man</li>
      <li>Here is</li>
      <li>List item</li>
    </ol>
    <h3>Unordered</h3>
    <ul>
      <li>Ok man</li>
      <li>Here is</li>
      <li>List item</li>
    </ul>
    <h2>Fancy hr</h2>
    <FancyHr />
    <h2>Code</h2>
    <pre>{fakeCode}</pre>
  </Layout>
)

export default SuperSecretStyleGuide
