import colors from './colors'

// Key reference at bottom of page: https://system-ui.com/theme/
const theme = {
  breakpoints: [
    /** iPhone 5 and smaller */
    '321px', // Larger than iPhone 5, smaller than iPad portrait
    '768px', // iPad portrait and larger
    '1024px', // iPad landscape and larger
    '1281px', // Larger than my laptop
  ],
  sizes: {
    // Remnants from when it was array
    // '0.512em',
    // '0.64em',
    // '0.8em',
    // '1em',
    // '1.25em',
    // '1.563em',
    // '1.953em',
    header: {
      mobile: '3rem',
      tabletAndUp: '4rem',
    },
    footerIcon: '2.441em',
    // '3.052em',
    containers: {
      tabletLand: '960px',
      widest: '1000px',
      reasonable: '1200px',
      crazyWide: '1260px',
      thatsRightBigFella: '1600px',
    },
  },
  globalFontRems: {
    default: '17px',
    sm: '18px',
    lg: '19px',
    xl: '20px',
  },
  fontSizes: [
    '0.512em',
    '0.64em',
    '0.8em',
    '1em',
    '1.25em',
    '1.563em',
    '1.953em',
    '2.441em',
    '3.052em',
  ],
  space: [
    0,
    '0.128em',
    '0.256em',
    '0.32em',
    '0.512em',
    '0.64em',
    '0.768em',
    '1em',
    '1.256em',
    '1.563em',
    '1.953em',
    '2.441em',
    '3.052em',
    '3.5em',
    '4em',
    '4.5em',
    '5em',
  ],
  fontWeights: {
    subTitle: 100,
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.67,
    heading: 1.15,
    small: 0.75,
  },
  radii: [0, '2px', '5px'],
  fonts: {
    body: 'Questrial',
    heading: 'Raleway', // seems to be needed despite redundancy
    monospace: 'Menlo, monospace',
  },
  // TODO: give er a try: https://brumm.af/shadows
  shadows: {
    small: '1px 1px 4px rgba(0, 0, 0, .25)',
    heavy: '0 0 9px 10px hsla(0, 0%, 70%, 0.8)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    whiteTextOnColorBg: '2px 2px 3px #555',
    usesHeroes: '1px 1px 1px hsla(0, 0%, 7%, 0.3)',
  },
  variants: {
    listUnstyled: {
      listStyle: 'none',
      pl: 0,
    },
  },
  buttons: {
    primary: {
      bg: 'buttonBg',
      borderColor: 'buttonBg',
      borderRadius: 2,
      borderStyle: 'solid',
      borderWidth: 2,
      boxShadow: 'none',
      color: 'white',
      cursor: 'pointer',
      fontSize: [2, 3], // delete if not needed
      px: [7, null, 6],
      position: 'relative',
      transition: '300ms',
      ':hover': {
        bg: [null, null, null, 'buttonBgHover'],
        borderColor: [null, null, null, 'buttonBgHover'],
      },
      '> a': {
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        display: 'block',
        position: 'relative',
        backgroundImage: 'none',
      },
    },
  },
  transitions: [300, 600], // TODO: understand this
}

theme.buttons.hollow = {
  ...theme.buttons.primary,
  color: 'buttonBg',
  bg: 'transparent',
  borderColor: 'currentColor',
  ':hover': {
    borderColor: 'buttonBgHover',
    bg: [null, null, null, 'transparent'],
    color: 'buttonBgHover',
    '> *': {
      color: 'buttonBgHover',
    },
  },
}

// e.g. "View live map" portfolio button
theme.buttons.monster = {
  ...theme.buttons.primary,
  fontSize: [4, null, 4],
  px: [8, null, 8],
}

// Link variants
theme.variants.linkAgainstDarkBg = {
  ...theme.variants.link,
  color: 'secondary.1',
  ':hover': {
    color: 'secondary.2',
  },
}

export default {
  colors,
  ...theme,
}
