// General/shame
export { default as basicMap } from '../images/icons/basic-map-icon.svg'

// UI
export { default as chat } from '../images/icons/ui/chat.svg'
export { default as contact } from '../images/icons/ui/contact.svg'
export { default as home } from '../images/icons/ui/home.svg'
export { default as info } from '../images/icons/ui/info.svg'
export { default as mapPin } from '../images/icons/ui/map-pin.svg'
export { default as person } from '../images/icons/ui/person.svg'
export { default as tag } from '../images/icons/ui/tag.svg'
export { default as tools } from '../images/icons/ui/tools.svg'
export { default as x } from '../images/icons/ui/x.svg'

// Social
export { default as facebook } from '../images/icons/social/facebook.svg'
export { default as github } from '../images/icons/social/github.svg'
export { default as instagram } from '../images/icons/social/instagram.svg'
export { default as linkedIn } from '../images/icons/social/linked-in.svg'
export { default as mail } from '../images/icons/social/mail.svg'
export { default as stackOverflow } from '../images/icons/social/stack-overflow.svg'
export { default as twitter } from '../images/icons/social/twitter.svg'

// Uses/examples
export { default as community } from '../images/icons/uses/community.svg'
export { default as conservation } from '../images/icons/uses/conservation.svg'
export { default as doubleArrow } from '../images/icons/ui/double-arrow.svg'
export { default as history } from '../images/icons/uses/history.svg'
export { default as hydrology } from '../images/icons/uses/hydrology.svg'
export { default as layers } from '../images/icons/ui/layers.svg'
export { default as recreation } from '../images/icons/uses/recreation.svg'
export { default as share } from '../images/icons/ui/share.svg'
export { default as thermometer } from '../images/icons/uses/thermometer.svg'
export { default as waterDroplet } from '../images/icons/uses/water-droplet.svg'
export { default as wildlife } from '../images/icons/uses/wildlife.svg'

// Advantages/reasons to use
export { default as currentLocation } from '../images/icons/reasons-to-use/map-pin-signal.svg'
export { default as customized } from '../images/icons/reasons-to-use/customized.svg'
export { default as dbChecked } from '../images/icons/reasons-to-use/db-checked.svg'
export { default as graphUpwardTrend } from '../images/icons/reasons-to-use/graph-upward-trend.svg'
export { default as interactive } from '../images/icons/reasons-to-use/interactive.svg'
export { default as mapTrace } from '../images/icons/reasons-to-use/map-trace.svg'
export { default as paperPlane } from '../images/icons/reasons-to-use/paper-plane.svg'
export { default as pinOnTimeline } from '../images/icons/reasons-to-use/pin-on-timeline.svg'
export { default as responsive } from '../images/icons/reasons-to-use/responsive.svg'
export { default as simpleResponsive } from '../images/icons/reasons-to-use/iconmonstr-responsive.svg'
export { default as swissArmyKnife } from '../images/icons/reasons-to-use/swiss-army-knife.svg'
