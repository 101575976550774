import React from 'react'
import { Text } from 'rebass/styled-components'

const TagsList = ({ tag, type }) => (
  <Text
    as="li"
    color="white"
    fontSize="1"
    sx={{
      px: 4,
      py: 1,
      borderRadius: 4,
      bg: type === 'industry' ? 'tagIndustry' : 'tagFeature',
      display: 'inline-block',
      mr: 2,
      mb: [0, null, 1],
    }}
  >
    {tag}
  </Text>
)

export default TagsList
