import React from 'react'
import { Box, Flex } from 'rebass/styled-components'

import Container from './container'
import FancyHr from './horizontal-rule'

export const borderArrow = () => {
  const height = 20

  return {
    content: "''",
    width: 0,
    height: 0,
    position: 'absolute',
    top: '20%',
    left: height * -1,
    borderTop: `${height}px solid transparent`,
    borderBottom: `${height}px solid transparent`,
    borderRight: `${height}px solid`,
    borderRightColor: 'secondary.1',
  }
}

const CreditBadge = () => (
  <a
    style={{
      backgroundColor: 'black',
      color: 'white',
      textDecoration: 'none',
      padding: '4px 6px',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1.2,
      display: 'inline-block',
      borderRadius: 3,
    }}
    href="https://unsplash.com/@freestocks?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
    target="_blank"
    rel="noopener noreferrer"
    title="Download free do whatever you want high-resolution photos from freestocks.org"
  >
    <span style={{ display: 'inline-block', padding: '2px 3px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          height: '12px',
          width: 'auto',
          position: 'relative',
          verticalAlign: 'middle',
          top: -2,
          fill: 'white',
        }}
        viewBox="0 0 32 32"
      >
        <title>unsplash-logo</title>
        <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z" />
      </svg>
    </span>
    <span style={{ display: 'inline-block', padding: '2px 3px' }}>
      freestocks.org
    </span>
  </a>
)

// CRED: https://css-tricks.com/notched-boxes/
// TODO: support other props
// Notched corners. Haven't actually tried as standalone component yet.
export const BuenosNotches = ({ notchSize, children }) => (
  <div
    style={{
      clipPath: () => `polygon(
        0% ${notchSize},
        ${notchSize} 0%,
        calc(100% - ${notchSize}) 0%,
        100% ${notchSize},
        100% 0%,
        100% calc(100% - ${notchSize}),
        calc(100% - ${notchSize}) 100%,
        ${notchSize} 100%,
        0% calc(100% - ${notchSize})
        )`,
    }}
  >
    {children}
  </div>
)

// Full-width page section with colored background
export const Tro = ({ heading, children, id, headingAs, color = 'white' }) => (
  <Flex
    id={id}
    alignItems="center"
    bg="midtroBg"
    my={13}
    py={[13, null, 15]}
    color={color}
    textAlign="center"
    sx={{
      a: {
        color: 'inherit',
      },
      'p:last-of-type': {
        mb: 0,
      },
    }}
  >
    <Container mx="auto">
      {headingAs ? (
        <Box as={headingAs} style={{ marginTop: 0, color: 'inherit' }}>
          {heading}
        </Box>
      ) : (
        <h2 style={{ marginTop: 0, color: 'inherit' }}>{heading}</h2>
      )}
      <FancyHr />
      {children}
    </Container>
  </Flex>
)

export default CreditBadge
