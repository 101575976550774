import React from 'react'
import { Box, Flex } from 'rebass/styled-components'

import SinglePin from '../../images/logo/abm-logo-single-pin.svg'

const Line = ({ lineColor = 'grays.2' }) => (
  <Box
    sx={{
      flex: 1,
      borderTopColor: lineColor,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      maxWidth: [100, 200, 300],
    }}
  />
)

const FancyHr = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    sx={{
      '.pin__pin': {
        fill: 'fancyHrPin',
      },
    }}
  >
    <Line />
    <Box as={SinglePin} width={30} />
    <Line />
  </Flex>
)
export default FancyHr
