import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`One-stop shop`}</h2>
    <p>{`No need to keep looking for a GIS company `}<em parentName="p">{`and`}</em>{` a web shop- you've come to the
right place. A Better Map's expertise in both disciplines means the best of both
worlds (geography pun intended).`}</p>
    <h2>{`Small company, big experience`}</h2>
    <p>{`We are a member-owned small business run by a lead developer with over a decade
of GIS knowledge and more than six years of programming experience. Think less
"marketing department" and more "make me a modern map".`}</p>
    <h2>{`From paper to production`}</h2>
    <p>{`Starting from scratch or already have your foot in the map door? Starting an
interactive map project can be a daunting task, but it doesn't need to be. We'll
walk you from planning all the way to the web map finish line.`}</p>
    <h2>{`Best practices`}</h2>
    <p>{`Not sure where your project will be in five years, or even five months? From
starting point to finished product we strive to follow best practices and
techniques to future-proof your project:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Your data in your hands.`}</em>{` No one knows your project like you do. Your data
is the core of your message so it should be conveniently available for you to
control and modify as needed.`}</li>
      <li parentName="ul"><em parentName="li">{`Responsibly responsive.`}</em>{` We believe in a mobile-first approach whenever
possible. With so many devices on the market today it is wise to design for
tomorrow, and this includes high-quality products that work great on screens
from mobile to monitors.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      