// TODO: convert file to JS
export default [
  { name: 'Email', icon: 'mail', url: null },
  { name: 'GitHub', icon: 'github', url: 'https://github.com/abettermap' },
  {
    name: 'Stack Exchange',
    icon: 'stackOverflow',
    url: 'https://stackexchange.com/users/1042890/abettermap?tab=accounts',
  },
  { name: 'Twitter', icon: 'twitter', url: 'https://twitter.com/abettermap' },
  {
    name: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/abettermap',
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/a.better.map/',
  },
  // {name: 'LinkedIn', url: ''}, // TODO if useful
]
