module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"portfolio":"/opt/build/repo/src/components/page-templates/portfolioPageTemplate.jsx","blog":"/opt/build/repo/src/components/page-templates/blogPostTemplate.jsx","default":"/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-abm-website","short_name":"A Better Map","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo/abm-logo-pins-only.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["/dev-404-page","/404","/404.html"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
