// TODO: move this file back to ../contact.jsx. Annoying w/other index.js!
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Button, Flex } from 'rebass/styled-components'
import { useForm } from 'react-hook-form'

import sendElectronicMail from '../../helpers/helpers'
import Layout from '../../components/layout'
import OtherDetails from '../../components/layout-pieces/contact-form-pieces'
import ContactInfo from '../../components/contact-form/contact-info'
import MapDetails from '../../components/contact-form/contact-map-details'

export const ContactForm = ({ includeDeets }) => {
  const { register, handleSubmit, errors } = useForm()
  // TODO: errors/validation actually needed? It's just a contact form...
  const onSubmit = formData => {
    sendElectronicMail({ formData })
  }

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      as="form"
      css={`
        label {
          align-items: center;
        }

        label input[type='text'] {
          margin-right: 5px;
        }
      `}
    >
      <p>
        Required fields are followed by{' '}
        <strong>
          <abbr title="required">*</abbr>
        </strong>
        .
        {!includeDeets &&
          ` If you'd like to fill out a form with additional details, `}
        {!includeDeets && (
          <>
            <GatsbyLink
              to="/contact/full-contact-form"
              title="Contact form with additional details"
            >
              go here
            </GatsbyLink>
            .
          </>
        )}
      </p>
      <ContactInfo register={register} includeDeets={includeDeets} />
      {includeDeets && <MapDetails register={register} />}

      <OtherDetails register={register} includeDeets={includeDeets} />
      <Box
        as="section"
        mb={7}
        pt={7}
        sx={{ borderTop: 'dashed 1px', borderTopColor: 'grays.3' }}
      >
        <Flex as="p" justifyContent="center">
          <Button type="submit" ref={register} variant="monster">
            Submit
          </Button>
        </Flex>
        {/* TODO: remove once you get a "Sending..." overlay */}
        <p style={{ textAlign: 'center' }}>
          <em>Please click Submit only once</em>
          <br />
          <small>
            Your information will be transmitted securely and will not be used
            for marketing purposes.
          </small>
        </p>
      </Box>
    </Box>
  )
}

const ContactPage = ({ pageContext, children, includeDeets }) => (
  <Layout
    title={includeDeets ? 'Contact us (full details)' : 'Contact us'}
    intro="Please complete the form below and we will reply shortly, or feel free to check the bottom of the page for other ways to reach us."
    pageContext={pageContext}
  >
    <ContactForm includeDeets={includeDeets}>{children}</ContactForm>
  </Layout>
)

export default ContactPage
