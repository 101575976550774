import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

// TODO: if you can ever get this work, try an actual Grid component:
// https://styled-system.com/table#grid-layout
const Container = props => {
  const { children } = props

  return (
    <Box
      maxWidth={[
        null,
        null,
        'containers.tabletLand',
        'containers.widest',
        'containers.reasonable',
      ]}
      mx={['auto', null, 2, 'auto']}
      px={[4, 6, 8, 10, 11]}
      {...props}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Container
