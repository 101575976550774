import React from 'react'
import { Box, Flex, Link, Button } from 'rebass/styled-components'

import IconResponsive from '../../images/icons/reasons-to-use/responsive.svg'
import Intro from './intro'

const HomeIntro = () => (
  <Intro isHome>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, null, '1fr 1.2fr'],
        gridTemplateRows: [
          '11rem auto',
          null,
          '15rem auto auto',
          null,
          'auto auto auto',
        ],
        gridTemplateAreas: [`"icon" "not-icon"`, null, null, `"icon not-icon"`],
        gridColumnGap: [null, null, 4, 12, 12],
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <HomeHeroIcon />
      <Box sx={{ gridArea: 'not-icon', px: [null, null, 8, 6] }} color="white">
        <h1 style={{ color: 'inherit' }}>Modern Maps for Modern Users</h1>
        <p>
          A Better Map designs and develops interactive maps for the web. Check
          out some examples and see what web maps can do for you.
        </p>
        <Flex justifyContent="center" alignItems="center" sx={{ py: 3 }}>
          {/* TODO: send event to GA on click */}
          <Button
            id="gtm-cta-home-hero"
            as={Link}
            href="#what-web-maps-can-do-for-you"
            title="What interactive maps can do for you"
            fontSize={4}
          >
            Get started
          </Button>
        </Flex>
      </Box>
    </Box>
  </Intro>
)

const HomeHeroIcon = () => (
  <Box
    justifyContent="center"
    alignItems="center"
    flexDirection={['column']}
    sx={{
      // CRED: (partial) https://codepen.io/bradleyboy/pen/lIqag?editors=1000
      // fill: 'url(#pattern--indefinite)',
      gridArea: 'icon',
      '.icon-responsive__phone': {
        fill: 'icons.homeIntro.phone',
      },
      '.icon-responsive__tablet': {
        fill: 'icons.homeIntro.tablet',
      },
      '.icon-responsive__monitor': {
        fill: 'icons.homeIntro.monitor',
      },
    }}
  >
    <Box
      as={IconResponsive}
      sx={{ size: ['14rem', null, '22rem', null, '24rem'] }}
    />
  </Box>
)

export default HomeIntro
