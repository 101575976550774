const gps = {
  heading: 'You are here',
  subheading:
    "Not there, or there, but HERE. GPS technology provides info based on users' current location.",
  text:
    'Web maps use geolocation found on most mobile devices to help users answer questions like:',
  list: [
    'How do I get to the closest hiking trail?',
    'What else is in the area?',
    'How many bird sightings are nearby?',
  ],
  icon: 'currentLocation',
  img: 'phone-user-train-stn.jpg',
  imgCaption: '',
  imgCred: '',
}

const layers = {
  heading: 'Got a dull map? Layer up.',
  subheading:
    "Dynamic baselayers reveal and enhance what's underneath your data.",
  text: 'Why choose just one background layer when you can have several?',
  list: [
    'Satellite/aerial imagery',
    'Roadmaps',
    'Topographic/terrain',
    'Something more customized. How about a <a href="https://api.mapbox.com/styles/v1/abettermap/ck5poswrp0god1iqejl2bqrfw.html?fresh=true&title=view&access_token=pk.eyJ1IjoiYWJldHRlcm1hcCIsImEiOiJjazVqengxMTgwOTB1M2pwbGNteHZkYTJrIn0.87lNhqvxIckDr8oZg_32Qg#11.01/40.6639/-4.0249/0.1" title="Mapbox treasure map" target="_blank" rel="noopener noreferrer" >treasure map</a>?',
  ],
  icon: 'layers',
  img: 'manhattan-at-night.jpg',
  imgCaption: '',
  imgCred: '',
}

const responsive = {
  heading: 'Responsibly responsive',
  subheading:
    'Tap, swipe, or click your way through a memorable map experience.',
  text:
    'Interactive maps work great on a variety of devices, and a mobile-friendly approach ensures satisfaction across platforms.',
  list: [],
  icon: 'simpleResponsive',
  img: 'pile-o-phones.jpg',
  imgCaption: '',
  imgCred: '',
}

const byod = {
  heading: 'Flexible sources: BYOData',
  subheading:
    'Web maps can consume a variety of data types. Just keep feeding them!',
  text:
    'Data sources are as varied as the projects they support, but web maps can be tailored to support your data, inluding formats like:',
  list: ['Shapefiles', 'Spreadsheets', 'Databases', 'KML (Google Earth)'],
  icon: 'dbChecked',
}

const limitless = {
  heading: 'Page size: letter, legal, or limitless?',
  subheading:
    'When it comes to big data in interactive maps, the sky (errr cloud?) is the limit.',
  text: 'Web maps do not share the size limitations of PDF, so you can:',
  list: [
    'Show large areas without compromising resolution.',
    'Keep all your info on one page for quick, easy access. Satisfy short attention spans!',
    'Cover more concepts that would not be possible on a predefined size.',
  ],
  icon: 'paperPlane',
}

const questions = {
  heading: 'Analyze and interpret',
  subheading:
    'Let your users query, filter, and search their way to analysis and interpretation.',
  text:
    'PDF maps only get you so far. Sometimes you need the robustness of a web map to answer questions like:',
  list: [
    'How many great blue herons were spotted in Larimer county in 2018?',
    'Which visitor centers are near my favorite cross-country ski trail and are open in winter?',
    'Where can I find a short, challenging hiking trail with great views?',
  ],
  icon: 'graphUpwardTrend',
}

const sharing = {
  heading: 'Sharing is caring',
  subheading:
    'Spread the word about a specific map feature quickly and directly.',
  text:
    'Through the magic that is the internet, web maps can take users directly to a feature through a URL. This means you can:',
  list: [
    'Skip the instructions, go straight to the source (e.g. this <a href="https://friendsofsleepingbear.org/sbht-i-map/popup/features/668" target="_blank" rel="noopener noreferrer" title="Direct link to Great Dunes vista">Lake Michigan viewpoint</a>)',
    'Share it via email, social media, whatever',
    'Show exactly what you want to show',
  ],
  icon: 'share',
}

const integration = {
  heading: 'Keep it real',
  subheading:
    '...real-<i>time</i>, that is. Integration with third-party platforms means one big happy map family.',
  text: 'Unlike PDFs, web maps can connect to dynamic services like:',
  list: [
    'Weather',
    'Google Street View',
    'Wildlife observations like bird sightings',
    'Social media (the <i>other</i> kind of tweets)',
  ],
  icon: 'pinOnTimeline',
  img: 'societal-addiction.jpg',
  imgCaption: '',
}

export const lessImportant = [byod, questions, limitless, sharing]
export default [gps, layers, responsive, integration]
