import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { convertToValidId } from '../../helpers/helpers'
import DynamicImage from './dynamic-img'

const MapFeature = ({ feature, relImgBaseDir }) => {
  const { heading, description, img } = feature

  // TODO: learn enough about Gatsby Image to apply it to portfolio pics
  // https://codebushi.com/using-gatsby-image/
  // https://medium.com/@kyle.robert.gill/ridiculously-easy-image-optimization-with-gatsby-js-59d48e15db6e
  // https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image
  return (
    <Box
      id={convertToValidId(heading)}
      sx={{
        display: [null, null, 'grid'],
        gridTemplateColumns: [null, null, '1fr 1fr'],
        textAlign: 'center',
      }}
    >
      <Box
        as={DynamicImage}
        imgName={img}
        relImgBaseDir={relImgBaseDir}
        wrapperStyle={{ overflow: 'visible' }}
        imgStyle={{ objectFit: 'contain' }}
      />
      <Flex justifyContent="center" flexDirection="column" px={[2, null, 8]}>
        <Text
          as="h3"
          mt={[5, null, null, 'unset']}
          sx={{
            '::after': {
              content: "''",
              width: '70%',
              mx: 'auto',
              height: '2px',
              bg: 'primary.0',
              mt: 4,
              display: 'block',
              mb: 4,
            },
          }}
        >
          {heading}
        </Text>
        <Box
          style={{ margin: 0 }}
          as="p"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Flex>
    </Box>
  )
}

export default MapFeature
