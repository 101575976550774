import React from 'react'
import { Flex, Box } from 'rebass/styled-components'

const Swatch = ({ colorName }) => (
  <Flex justifyContent="center" alignItems="center" bg={colorName}>
    {colorName}
  </Flex>
)

const ColorSchemeDemo = () => {
  const colors = ['primary', 'secondary']

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        textAlign: 'center',
        mb: 6,
        fontWeight: 'bold',
        color: 'white',
      }}
    >
      {colors.map(color => (
        <div>
          <Swatch key={`${color}.0`} colorName={`${color}.0`} />
          <Swatch key={`${color}.1`} colorName={`${color}.1`} />
          <Swatch key={`${color}.2`} colorName={`${color}.2`} />
          <Swatch key={`${color}.3`} colorName={`${color}.3`} />
          <Swatch key={`${color}.4`} colorName={`${color}.4`} />
        </div>
      ))}
    </Box>
  )
}

export default ColorSchemeDemo
