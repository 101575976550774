import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Flex, Button } from 'rebass/styled-components'

import IconMapTrace from '../../images/icons/reasons-to-use/map-trace.svg'
import IconCustomized from '../../images/icons/reasons-to-use/customized.svg'
import IconInteractive from '../../images/icons/reasons-to-use/interactive.svg'
import IconSwissKnife from '../../images/icons/reasons-to-use/swiss-army-knife.svg'
import IconCurrentLocation from '../../images/icons/reasons-to-use/map-pin-signal.svg'

const ShowcaseItemIcon = ({ icon, iconHeight }) => {
  // TODO: import from icons.js instead
  const iconKeys = {
    'map-trace': IconMapTrace,
    versatile: IconSwissKnife,
    interactive: IconInteractive,
    customized: IconCustomized,
    currentLocation: IconCurrentLocation,
  }

  return (
    <Flex alignItems="center" justifyContent="center" height={iconHeight}>
      <Box
        as={iconKeys[icon]}
        height="100%"
        sx={{
          fill: 'midtroIcon',
        }}
      />
    </Flex>
  )
}

const ShowcaseItem = ({ item }) => {
  const { heading, body, icon, btnText, btnLink } = item
  const iconHeight = '8rem'

  return (
    <Box mb={12}>
      <ShowcaseItemIcon icon={icon} iconHeight={iconHeight} />
      <div>
        <Flex
          height="4rem"
          my={4}
          as="h3"
          color="inherit"
          justifyContent="center"
          alignItems="center"
        >
          {heading}
        </Flex>
        <Box
          as="hr"
          sx={{ borderColor: 'midtroBar' }}
          width="75%"
          mb={8}
          mx="auto"
        />
      </div>
      <p>{body}</p>
      <Button as={GatsbyLink} to={btnLink}>
        {btnText}
      </Button>
    </Box>
  )
}

const Showcase = ({ items }) => (
  <Box
    textAlign="center"
    color="white"
    mb={11}
    mx="auto"
    px={[4, null, 6, 8, 10]}
    maxWidth="containers.thatsRightBigFella"
    sx={{
      display: [null, null, 'grid'],
      // FIXME: make this not cause a new row on iPad portrait
      gridTemplateColumns: [null, null, `repeat(auto-fit, minmax(370px, 1fr))`],
      gridRowGap: 10,
      gridColumnGap: 10,
    }}
  >
    {items.map(item => (
      <ShowcaseItem key={item.icon} item={item} />
    ))}
  </Box>
)

export default Showcase
