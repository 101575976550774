import React, { Component } from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import Slider from 'react-slick'
import '../../styles/slick/slick.css'
import '../../styles/slick/slick-theme.css'

import DynamicImage from './dynamic-img'

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainNavSlider: null,
      thumbsNavSlider: null,
    }
  }

  componentDidMount() {
    this.setState({
      mainNavSlider: this.mainSliderRef,
      thumbsNavSlider: this.thumbsSliderRef,
    })
  }

  render() {
    // TODO: fix the eslint function errors below
    const { children, imagesConfig, relImgBaseDir } = this.props
    const { thumbsNavSlider, mainNavSlider } = this.state

    return (
      <>
        <Slider
          arrows={false}
          asNavFor={thumbsNavSlider}
          ref={slider => {
            this.mainSliderRef = slider

            return null
          }}
        >
          {children}
        </Slider>
        <Box
          as={Slider}
          asNavFor={mainNavSlider}
          ref={slider => {
            this.thumbsSliderRef = slider

            return null
          }}
          centerMode
          centerPadding="0"
          slidesToShow={3}
          swipeToSlide
          focusOnSelect
          mx="auto"
          mt={[null, null, 8]}
          pt={5}
          maxWidth={['calc(100vw - 2.5rem)', null, '80%', '60%', '50%']}
          sx={{
            borderColor: 'primary.0',
            borderStyle: 'solid',
            borderWidth: 0,
            borderTopWidth: 2,
            borderBottomWidth: 2,
          }}
        >
          {imagesConfig.map(({ img, heading }) => (
            <SliderNavThumb key={img} {...{ img, heading, relImgBaseDir }} />
          ))}
        </Box>
      </>
    )
  }
}
const SliderNavThumb = ({ img, relImgBaseDir, heading }) => (
  <Flex mx="auto" flexDirection="column" justifyContent="center" py={4}>
    <DynamicImage
      imgName={img}
      relImgBaseDir={relImgBaseDir}
      wrapperStyle={{
        height: '5rem',
        width: '5rem',
        cursor: 'pointer',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      imgStyle={{
        borderRadius: '5px',
        border: 'solid 1px rgba(15,15,15,0.1)',
      }}
    />
    <Text
      lineHeight="1"
      fontSize={[1, null, 2]}
      fontWeight="bold"
      textAlign="center"
      mt={3}
    >
      {heading}
    </Text>
  </Flex>
)
