import React from 'react'

import Layout from '../layout'

export default function Template({ pageContext, children }) {
  const { frontmatter } = pageContext
  const { title, intro, date } = frontmatter

  return (
    <Layout
      seoTitle={`${title} | ABM Blog`}
      title={title}
      intro={intro}
      pageContext={pageContext}
    >
      <i>Written on {date}</i>
      {children}
    </Layout>
  )
}
