import React from 'react'
// TODO: import PropTypes from "prop-types"

import Container from './container'
import Intro from './intro'
import Breadcrumbs from './breadcrumbs'

const MainContent = ({
  title,
  intro,
  children,
  crumbs = [],
  skipCrumbs = false,
}) => (
  <>
    {intro ? <Intro title={title} body={intro} /> : null}
    <Container
      bg="white"
      sx={{
        borderRadius: [null, null, 5], // looks silly on sm screens
        my: [3, 4, 6],
        py: 9,
        // TODO: only hover on non-mobile. Do this everywhere that's affected.
        // TODO: remove these if not needed (heh, "not" needed)
        'button > a:visited:not(.immune-to-override)': {
          color: 'white',
        },
        'button > a:hover:not(.immune-to-override)': {
          color: 'white',
        },
      }}
    >
      {!skipCrumbs ? (
        <Breadcrumbs crumbs={crumbs} currentPageTitle={title} />
      ) : null}
      {children}
    </Container>
  </>
)

export default MainContent
