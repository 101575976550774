import React, { useState } from 'react'
import { Box, Button, Flex } from 'rebass/styled-components'

import { x as IconX } from '../../helpers/icons'

const Modal = ({ children, origElemSx, modalChildSx }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box
        sx={{ ...origElemSx }}
        onClick={() => {
          if (!open) {
            setOpen(true)
            document.body.style.overflowY = 'hidden'
          }
        }}
      >
        {children}
      </Box>
      <Box
        style={{
          display: open ? 'flex' : 'none',
        }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          bg: 'rgba(15,15,15,0.9)',
          bottom: 0,
          color: 'white',
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <ModalCloseBtn setOpen={setOpen} />
        <Box sx={{ ...modalChildSx }}>{children}</Box>
      </Box>
    </>
  )
}

const ModalCloseBtn = ({ setOpen }) => (
  <Flex
    as={Button}
    size="2.5rem"
    justifyContent="center"
    alignItems="center"
    bg="transparent"
    sx={{
      position: 'absolute',
      top: 4,
      right: 4,
      p: 3,
      px: 3,
      py: 3,
      borderColor: 'transparent',
    }}
    onClick={() => {
      setOpen(false)
      document.body.style.overflowY = 'auto'
    }}
    title="Click to close"
  >
    <Box as={IconX} size="100%" sx={{ fill: 'white' }} />
  </Flex>
)
export default Modal
