import React from 'react'
import { Flex, Text } from 'rebass/styled-components'

import Container from './container'

const DefaultIntroTemplate = ({ title, body }) => (
  <>
    <Text as="h1" mb={3} color="white">
      {title}
    </Text>
    <p>{body}</p>
  </>
)

const Intro = ({ title = '', body = '', children, isHome = false }) => (
  <Flex
    as="header"
    bg={isHome && 'homeFullHeightBg'}
    alignItems="center"
    color="white"
    flexDirection={['column', null, null, 'row']}
    justifyContent="center"
    // TODO: make way less fragile as it's not coming from theme
    sx={{
      height: isHome && '100vh',
      top: isHome && '-4rem', // looks ok on phone despite different header ht
      position: 'relative',
    }}
    py={isHome ? 0 : [10, null, 12]}
  >
    <Container>
      {children || <DefaultIntroTemplate title={title} body={body} />}
    </Container>
  </Flex>
)

export default Intro
