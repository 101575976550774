import React from 'react'
import { Box } from 'rebass/styled-components'

import Layout from '../layout'
import BigCenteredBtn from '../layout-pieces/big-centered-btn'
import SimpleSlider from '../layout-pieces/slick-slider'
import MapFeature from '../layout-pieces/map-feature'

const PortfolioPageTemplate = ({ pageContext }) => {
  const { frontmatter } = pageContext
  const {
    name: projectName,
    intro,
    map_features: features,
    img_dir: relImgBaseDir,
    project_url: projectUrl,
  } = frontmatter
  const imagesConfig = features.map(({ img, heading }) => ({
    img,
    heading,
  }))

  return (
    <Layout
      seoTitle={`${projectName} | ABM Portfolio`}
      title={projectName}
      intro={intro}
      pageContext={pageContext}
    >
      <h2>Map features</h2>
      <Box mt={10}>
        <SimpleSlider {...{ relImgBaseDir, imagesConfig }}>
          {features.map((feature, index) => (
            <MapFeature
              key={feature.heading}
              {...{ feature, index, relImgBaseDir }}
            />
          ))}
        </SimpleSlider>
      </Box>
      <BigCenteredBtn
        openInNewTab
        href={projectUrl}
        title={`View ${projectName} map`}
        variant="monster"
      >
        View live map
      </BigCenteredBtn>
    </Layout>
  )
}

export default PortfolioPageTemplate
