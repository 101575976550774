import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link, Flex, Button } from 'rebass/styled-components'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from '@reach/router'

// Determine if nav link is for the current page
const isLinkForActivePage = (currentRelPath, linkUrl) => {
  const HOME_URL = '/'

  // Exact match
  if (linkUrl === currentRelPath) {
    return true
  }

  // Match nested routes
  if (currentRelPath.includes(linkUrl) && linkUrl !== HOME_URL) {
    return true
  }

  return false
}

export const NavLinkInner = ({
  title,
  text,
  to,
  isActivePage,
  gtmClassName,
}) => {
  const linkStyle = {
    // Colored bottom border for link corresponding to current page
    borderBottomStyle: 'solid',
    borderBottomWidth: 3,
    // prettier-ignore
    borderBottomColor: isActivePage ?
                'activeNavLinkColor' :
                'transparent',
    ':hover': {
      borderBottomColor: [null, null, null, !isActivePage && 'white'],
    },
  }

  return (
    <Link
      className={gtmClassName}
      title={title}
      to={to}
      as={GatsbyLink}
      fontSize={[3, 4, null, 3]}
      color="white"
      sx={linkStyle}
    >
      {text}
    </Link>
  )
}

const NavLink = ({
  title,
  text,
  to,
  isButton,
  gtmClassName = 'gtm-link-main-nav',
}) => (
  <Flex
    as="li"
    alignItems="center"
    justifyContent="center"
    py={[8, null, null, 'unset']}
    ml={[null, null, null, 7]}
  >
    <Location>
      {({ location }) => {
        const isActivePage = isLinkForActivePage(location.pathname, to)

        // TODO: break this out in a non-gross way
        return (
          (isButton && (
            <Button
              id="gtm-cta-main-nav"
              as={GatsbyLink}
              to={to}
              title={title}
              className={gtmClassName}
              px={[null, null, null, 6]}
              fontSize={[null, null, null, 2]}
            >
              {text}
            </Button>
          )) || (
            <NavLinkInner
              {...{ title, text, to, isButton, gtmClassName }}
              isActivePage={isActivePage}
            />
          )
        )
      }}
    </Location>
  </Flex>
)

export default NavLink
