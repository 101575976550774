import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page-templates/defaultPageTemplate.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Your project, your style`}</h2>
    <p>{`Interactive maps should be as unique as their owner, so `}<em parentName="p">{`customization`}</em>{` is an
essential concept in the maps we provide. We focus on what looks and feels right
for your project or brand instead of a one-size-fits-all approach. Take a look
at `}<a parentName="p" {...{
        "href": "/portfolio/sheridan-community-land-trust"
      }}>{`Sheridan Community Land Trust`}</a>{`,
for example, to get an idea of how customized and unique these maps can be.`}</p>
    <h2>{`Your project, your data`}</h2>
    <p>{`Since you understand your project best, your data should always be in your
hands. We can work with you on creating a workflow to `}<em parentName="p">{`control your own content`}</em>{`
whenever you need, including:`}</p>
    <ul>
      <li parentName="ul">{`Map layers`}</li>
      <li parentName="ul">{`Descriptive text and other written copy`}</li>
      <li parentName="ul">{`Media such as photos, video, or linkable PDFs`}</li>
    </ul>
    <p>{`Your `}<em parentName="p">{`data formats`}</em>{` can be flexible as well, including GIS, spreadsheets, and
cloud storage. Whatever the format, we will make sure it is comfortable and
maintainable for your project and technical skill level.`}</p>
    <h2>{`Mobile-friendly, mobile first`}</h2>
    <p>{`Mobile devices have become so ubiquitous in modern society that they cannot be
overlooked when designing digital products. A Better Map strives to create
`}<em parentName="p">{`responsive, mobile-friendly maps`}</em>{` that look great and work swimmingly across
the ever-growing landscape of screen sizes and web browsers.`}</p>
    <h2>{`Performance`}</h2>
    <p>{`While high-speed internet has improved over the years, there is still no room
for sloppy coding or neglect of best practices when it comes to `}<em parentName="p">{`web
performance`}</em>{`. A Better Map creates optimized interactive maps to meet the
impatient demands of "Generation Distractible", and we strive to write good code
that will satisfy their children in "Generation Extremely Distractible".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      