import { darken, lighten, transparentize } from 'polished'

// Base definitions
const grays = [
  'hsl(0, 0%, 93%)',
  'hsl(0, 0%, 88%)',
  'hsl(0, 0%, 83%)',
  'hsl(0, 0%, 78%)',
  'hsl(0, 0%, 73%)',
  'hsl(0, 0%, 68%)',
  'hsl(0, 0%, 63%)',
  'hsl(0, 0%, 58%)',
  'hsl(0, 0%, 53%)',
  'hsl(0, 0%, 48%)',
  'hsl(0, 0%, 43%)',
  'hsl(0, 0%, 38%)',
  'hsl(0, 0%, 33%)',
  'hsl(0, 0%, 28%)',
  'hsl(0, 0%, 23%)',
  'hsl(0, 0%, 18%)',
]

// More colors inspirations/tuts
// https://alistapart.com/article/mixing-color-for-the-web-with-sass/
// https://www.gomasuga.com/
// https://ferdychristant.com/mastering-color-palettes-with-sass-ed124b1f8920

// Scheme inspirations
// https://coolors.co/05668d-427aa1-ebf2fa-679436-a5be00
// https://coolors.co/114b5f-1a936f-88d498-c6dabf-f3e9d2
const colors = {
  grays,
  black: '#222',
  white: '#fff',
  runForestRun: '#2c5f2d',
  iMossTryThis: '#97bc62',
  // coolors.co/233d4d-fe7f2d-fcca46-a1c181-619b8a
  japaneseIndigo: '#233D4D',
  princetonOrange: '#FE7F2D',
  sunglow: '#FCCA46',
  // END coolors.co/233d4d-fe7f2d-fcca46-a1c181-619b8a
  queenBlue: '#427AA1',
  antiFlashWhite: '#EBF2FA',
  pleasantAssGreen: '#679436',
  limerick: '#A5BE00',
  orange: '#FE7F2D',
  midnightGreen: '#114B5F',
  // SRC W/PURPS: https://coolors.co/483c46-3c6e71-70ae6e-beee62-f4743b
  purpleTaupe: '#483C46',
  myrtleGreen: '#3C6E71',
  iguanaGreen: '#70AE6E',
  inchworm: '#BEEE62',
  orangeCrayola: '#F4743B',
  pseudoForest: 'hsl(123, 15%, 48%)',
  pseudoMoss: 'hsl(85, 38%, 68%)',
  // END SRC W/PURPS
}

// TODO: do something else besides repeating `colors.stuff`, jeez louise
// Theme
const primary = colors.runForestRun
const secondaryBase = 'rgb(9,69,85)'
const secondary = secondaryBase

// TODO: one more shade for each set
colors.primary = [
  lighten(0.3, primary),
  lighten(0.2, primary),
  lighten(0.1, primary),
  primary,
  darken(0.1, primary),
  darken(0.2, primary),
  darken(0.3, primary),
]

colors.secondary = [
  lighten(0.3, secondary),
  lighten(0.2, secondary),
  lighten(0.1, secondary),
  secondary,
  darken(0.05, secondary),
  darken(0.1, secondary),
  darken(0.15, secondary),
]

const text = {
  bodyText: colors.grays[14],
  mainHeadingText: colors.grays[15],
}

const links = {
  linkColor: colors.primary[2],
  linkHover: colors.primary[3],
  linkColorOnDarkBg: colors.primary[1],
  linkGradientBg: 'hsla(137, 55%, 53%, 0.6)',
}

links.linkColorOnDarkBgHover = darken(0.15, links.linkColorOnDarkBg)

const nav = {
  activeNavLinkColor: colors.primary[2],
  mobileNavBg: colors.secondary[4],
  topBarBg: colors.secondary[5],
}

const footer = {
  footerLinks: colors.grays[0], // G.E. if used w/fancy gradient BG
  footerLinksHover: links.linkColorOnDarkBgHover,
  footerBg: transparentize(0.4, colors.secondary[5]),
  footerLogoBndry: colors.grays[4],
  socialLinkIconBg: colors.secondary[3],
}

footer.socialLinkIconBgHover = darken(0.1, footer.socialLinkIconBg)

const buttons = {
  buttonBg: colors.primary[2],
}

buttons.buttonBgHover = darken(0.1, buttons.buttonBg)

// e.g. the little padded guys on the portfolio landing page
const tags = {
  tagFeature: colors.secondary[1],
  tagIndustry: colors.secondary[0],
}

const tableOfContents = {
  usesTocBgColor: colors.primary[2],
}

tableOfContents.usesTocBgColorHover = darken(
  0.075,
  tableOfContents.usesTocBgColor
)

const rando = {
  homeFullHeightBg: colors.secondary[4],
  midtroBar: colors.secondary[1],
  midtroBg: colors.secondary[3],
  midtroIcon: colors.grays[8],
  advantagesImgShadow: transparentize(0.5, colors.purpleTaupe),
  fancyHrPin: colors.primary[2],
}

const icons = {
  homeIntro: {
    phone: colors.secondary[3],
    monitor: colors.secondary[3],
    tablet: colors.secondary[3],
  },
  logo: {
    text: colors.white,
    icon: colors.primary[2],
  },
  usesIntros: {
    bg: colors.secondary[2],
    border: colors.secondary[3],
    icon: colors.secondary[1],
  },
}

export default {
  ...text,
  ...links,
  ...nav,
  ...footer,
  ...buttons,
  ...tags,
  ...tableOfContents,
  ...rando,
  ...colors,
  icons,
}
