import React from 'react'
import { Button } from 'rebass/styled-components'

class BackToTopBtn extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      bgAlpha: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { _isMounted } = this
    const { bgAlpha } = this.state
    const verticalThreshold = window.screen.availHeight / 1.25

    if (window.scrollY > verticalThreshold && _isMounted) {
      this.setState({ bgAlpha: 0.9 })
    } else if (window.scrollY < verticalThreshold && bgAlpha) {
      this.setState({ bgAlpha: 0 })
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { _isMounted } = this
    const { bgAlpha } = this.state
    const btnStyle = {
      alignItems: 'center',
      borderRadius: '100%',
      bottom: '20px',
      display: 'flex',
      fontSize: '1.5rem',
      justifyContent: 'center',
      lineHeight: 1.5,
      opacity: bgAlpha,
      padding: 0,
      position: 'fixed',
      right: '20px',
      transform: `translateY(${bgAlpha ? 0 : '150%'}) rotate(270deg)`,
      transition: '500ms',
      zIndex: 2,
    }

    return (
      _isMounted && (
        <Button
          title="Back to top"
          onClick={this.scrollToTop}
          size="3rem"
          style={btnStyle}
        >
          {'\u279C'}
        </Button>
      )
    )
  }
}

export default BackToTopBtn
