import { graphql, useStaticQuery } from 'gatsby'

// TODO: stop using external-file query, too simple and dynamic to justify it.
// Just move it into footer.jsx.
const FooterLinksQuery = () => {
  const FooterData = useStaticQuery(
    graphql`
      query FooterLinksQuery {
        site {
          siteMetadata {
            tagline
          }
        }
      }
    `
  )

  return FooterData
}

export default FooterLinksQuery
