import React from 'react'

import Layout from '../../components/layout'
import BigCenteredBtn from '../../components/layout-pieces/big-centered-btn'

const ContactPage = ({ pageContext }) => (
  <Layout
    seoTitle="Form submitted successfully"
    title="Contact form submitted"
    intro="Your info was submitted successfully. We will get back to you shortly."
    pageContext={pageContext}
  >
    <h2>Thanks!</h2>
    <p>We appreciate your interest and will reply soon.</p>
    <BigCenteredBtn to="/">Back to Homepage</BigCenteredBtn>
  </Layout>
)

export default ContactPage
